import React from 'react';
import classnames from 'classnames';

import { SortDirection } from '../../../enums';
import Arrows from './Arrows';
import styles from './Table.module.scss';
import { ColumnConfig } from './type';

export interface TableColumnHeaderProps extends Omit<ColumnConfig, 'onSortingClick'> {
  selectedSortKey?: string;
  selectedSortDirection?: SortDirection;
  isSortable: boolean;
  isSticky?: boolean;
  updateSort: (headerKey: string, resetSortDirection: boolean) => void;
  compact?: boolean;
}

const renderArrows = (config: {headerKey: string; sortKey?: string; isSortable: boolean; sortDirectionType?: SortDirection}) => {
  const { headerKey, sortKey, sortDirectionType = SortDirection.ASC, isSortable } = config;

  if (!isSortable) {
    return null;
  }
  return <Arrows
    headerKey={headerKey}
    sortDirection={sortDirectionType}
    sortKey={sortKey}
  />;
};

const TableColumnHeader: React.FC<TableColumnHeaderProps> = (props)  => {
  const { className, headerKey, updateSort, selectedSortDirection, selectedSortKey, isSortable, isSticky, compact } = props;

  const onHeaderColumnClick = isSortable ? () => {
    updateSort(headerKey, headerKey !== selectedSortKey);
  } : undefined;

  const thClassNames = classnames([
    styles.Table__ColumnHeader,
    !!isSortable && styles.Table__ColumnHeader__ColumnSortable,
    isSticky && styles.Table__HeaderRow__sticky,
    className,
  ]);

  const contentClassNames = classnames(
    styles.Table__ColumnHeader__content,
    {
      [styles['Table__ColumnHeader__content--compact']]: compact,
    });

  return <th
    className={thClassNames}
    data-testid={`col-${headerKey}`}
    onClick={onHeaderColumnClick}>
    <div className={contentClassNames}>
      {props.name}
      {renderArrows({
        headerKey,
        isSortable,
        sortDirectionType: selectedSortDirection,
        sortKey: selectedSortKey,
      })}
    </div>
  </th>;
};

export default TableColumnHeader;
