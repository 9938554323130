import React, { useCallback } from 'react';
import * as generateArchiveDownloadUrl from '../../graphql/generateArchiveDownloadUrl';
import { useLazyQuery, useQuery } from '@apollo/client';
import styles from './ArchiveDownload.module.scss';
import { useParams } from 'react-router-dom';
import Loading from '../system/Loading/Loading';
import GridContainer from '../system/GridContainer/GridContainer';
import InlineAlert, { InlineAlertType } from '../system/InlineAlert/InlineAlert';

interface ArchiveDownloadLinkProps {
  id: number;
}

interface ArchiveDirectDownloadParams {
  objectId: string;
}

export const ArchiveDownloadLink: React.FC<ArchiveDownloadLinkProps> = ({ id, children }) => {
  const [generateDownloadUrl] = useLazyQuery<generateArchiveDownloadUrl.ResultType, generateArchiveDownloadUrl.VariablesType>(
    generateArchiveDownloadUrl.query,
    {
      onCompleted: (data) => {
        if (window) {
          window.open(data.generateArchiveDownloadUrl, '_blank')!.focus();
        }
      },
    },
  );

  const handleClick = useCallback(() => {
    generateDownloadUrl({
      variables: {
        id,
      },
    });
  },                              [generateDownloadUrl, id]);

  return (
    <button className={styles.ArchiveDownloadLink} onClick={handleClick}>
      {children}
    </button>
  );
};

export const ArchiveDirectDownload: React.FC = () => {
  const { objectId } = useParams<ArchiveDirectDownloadParams>();
  const { error, data, loading } = useQuery<generateArchiveDownloadUrl.ResultType, generateArchiveDownloadUrl.VariablesType>(
    generateArchiveDownloadUrl.query,
    {
      variables: { objectId: objectId ? +objectId : undefined },
    },
  );

  if (error) {
    return (
      <InlineAlert type={InlineAlertType.DataError}>
        <p>Unable to retrieve archive data from server</p>
      </InlineAlert>
    );
  }

  if (data) {
    if (window) {
      window.open(data.generateArchiveDownloadUrl, '_self');
    }

    return (
      <>
        <h1>Release</h1>
        <p>File Downloaded.</p>
        <GridContainer item={true} gap="standard">
          <p>View more files in the <a href="/releases">Release Section</a></p>
        </GridContainer>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <h1>Releases</h1>
        <p>Accessing requested file</p>
        <GridContainer item={true} gap="standard">
          <Loading />
        </GridContainer>
      </>
    );
  }

  return null;

};
