import classnames from 'classnames';
import React from 'react';
import Icon, { IconDef } from '../Icon/Icon';
import { CheckRound, Close, Error, InfoFill } from '../Icon/Icons';
import styles from './InlineAlert.module.scss';

export enum InlineAlertType {
  Success = 'success',
  DataError = 'error',
  Info = 'info',
  Breach = 'breach',
}
export interface InlineAlertProps {
  type: InlineAlertType;
  dismiss?: () => void;
  className?: string;
}

export const InlineAlert: React.FC<InlineAlertProps> = (props) => {
  const inlineAlertClasses = classnames(
    styles.InlineAlert,
    props.className,
    { [styles[`InlineAlert--${props.type}`]]: true },
  );

  const getIconColor: () => string = () => {
    switch (props.type) {
      case InlineAlertType.Success:
        return 'green';
      case InlineAlertType.DataError:
        return 'red';
      case InlineAlertType.Breach:
        return 'purple';
      case InlineAlertType.Info:
      default:
        return 'light-blue';
    }
  };

  const getIcon: () => React.FC<IconDef> = () => {
    switch (props.type) {
      case InlineAlertType.Success:
        return CheckRound;
      case InlineAlertType.DataError:
        return Error;
      case InlineAlertType.Breach:
      case InlineAlertType.Info:
      default:
        return InfoFill;
    }
  };

  return (
    <div className={inlineAlertClasses}>
      <Icon icon={getIcon()} size={'24'} color={getIconColor()} />
      {props.children}
      {props.dismiss && (
        <button data-test-id={'dismiss-icon'} onClick={props.dismiss}>
          <Icon icon={Close} size={'24'} color={'dark-orange'} />
        </button>
      )}
    </div>
  );
};

export default InlineAlert;
