import classnames from 'classnames';
import React from 'react';
import styles from './Label.module.scss';

interface Props {
  for: string;
  text: string;
  className?: string;
}

const labelIdentifier = (str: string) => str.replace(' ', '_');

const Label: React.FC<Props> = (props) => {
  const className = classnames(props.className || styles['Label']);

  return (
    <label htmlFor={props.for} className={className}>{props.text}</label>
  );
};

export { labelIdentifier };

export default Label;
