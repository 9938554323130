import Auth from '@aws-amplify/auth';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../system/Button/Button';
import { InlineAlert, InlineAlertType } from '../system/InlineAlert/InlineAlert';
import Input from '../system/Input/Input';
import { AuthState } from './authenticator';
import styles from './authButtons.module.scss';
import { useApolloClient } from '@apollo/client';
import * as getClient from '../../graphql/getClient';

interface LoginProps {
  onStateChange: (newState: AuthState, data?: any) => void;
}

const Login: React.FC<LoginProps> = ({ onStateChange }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAttemptingSignIn, setIsAttemptingSignIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const apolloClient = useApolloClient();

  function useKey(key: string, cb: any) {
    const callbackRef = useRef(cb);

    useEffect(() => {
      callbackRef.current = cb;
    });

    useEffect(() => {
      function handle(event: { code: string; }) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
      document.addEventListener('keypress', handle);
      return () => document.removeEventListener('keypress', handle);
    },        [key]);
  }

  const checkContact = useCallback(
    (user) => {
      // We know that all users are verified, as they are created by admins
      onStateChange('signedIn', user);
    },
    [onStateChange],
  );

  const signIn = useCallback(async () => {
    try {
      setIsAttemptingSignIn(true);
      setErrorMessage(null);

      if (!username || username.trim() === '') {
        setErrorMessage('Email address cannot be empty');
        setIsAttemptingSignIn(false);
        return;
      }

      if (!password) {
        setErrorMessage('Password cannot be empty');
        setIsAttemptingSignIn(false);
        return;
      }
      const user = await Auth.signIn(username.toLowerCase().trim() || '', password);
      const getClientQuery = await apolloClient.query<getClient.ResultType>({
        query: getClient.query,
        fetchPolicy: 'no-cache',
      });

      if (getClientQuery.data?.client.isSuspended) {
        setErrorMessage('Your account has been suspended. Please contact your Client Success Manager.');
        setIsAttemptingSignIn(false);
        Auth.signOut();
        return;
      }

      if (getClientQuery.data?.client.account.active === false) {
        setErrorMessage('Your account has been suspended. Please contact your Client Success Manager.');
        setIsAttemptingSignIn(false);
        Auth.signOut();
        return;
      }

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        onStateChange('requireNewPassword', user);
      } else if (user.challengeName) {
        setIsAttemptingSignIn(false);
        throw new Error(`Unexpected challengeName: '${user.challengeName}'`);
      } else {
        checkContact(user);
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        setIsAttemptingSignIn(false);
        throw new Error('User is not confirmed');
      } else if (err.code === 'PasswordResetRequiredException') {
        onStateChange('forgotPassword');
      } else if (err.code === 'UserNotFoundException') {
        setIsAttemptingSignIn(false);
        setErrorMessage('Incorrect username or password.');
      } else {
        let errorMessage = err;

        if (err.message) {
          errorMessage = err.message;
        }
        setErrorMessage(errorMessage);
        setIsAttemptingSignIn(false);
      }
    }
  },                         [checkContact, onStateChange, username, password, apolloClient]);

  useKey('Enter', signIn);

  return (
    <div>
      <h1>Login</h1>

      {errorMessage ? (
        <InlineAlert type={InlineAlertType.DataError}>
          <p>{errorMessage}</p>
        </InlineAlert>
      ) : null}

      <div>
        <Input
          type="text"
          label="Email Address"
          name="username"
          disabled={isAttemptingSignIn}
          onChange={e => setUsername((e.target as HTMLTextAreaElement).value)}
        />
      </div>
      <div>
        <Input
          type="password"
          label="Password"
          name="password"
          disabled={isAttemptingSignIn}
          onChange={e => setPassword((e.target as HTMLTextAreaElement).value)}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button disabled={isAttemptingSignIn} onClick={() => signIn()} testId={'signIn'}>
          Login
        </Button>
        <Button
          secondary={true}
          disabled={isAttemptingSignIn}
          onClick={() => onStateChange('forgotPassword')}
          testId={'forgotPassword'}
        >
          Forgot your password?
        </Button>
      </div>
    </div>
  );
};

export default Login;
