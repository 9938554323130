import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { GetProductCategoriesForClient } from './types/GetProductCategoriesForClient';

export type ResultType = GetProductCategoriesForClient;

export const query = gql`
  query GetProductCategoriesForClient{
    productCategoriesForClient {
      id
      name
    }
  }`;
