import React, { useEffect, useRef, useState } from 'react';
import EcosystemLogo from '../EcosystemLogo/EcosystemLogo';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import styles from './EcosystemHeader.module.scss';
import classNames from 'classnames';

interface EcosystemHeaderProps {
  email: string;
  isAuthenticated: boolean;
  logout: () => void;
  simplified?: boolean;
  headerLinks?: [{ text: string, href: string }];
}

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },        []);

  return windowWidth;
}

const EcosystemHeader: React.FC<EcosystemHeaderProps> = (props) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dropdownToggleRef = useRef<HTMLDivElement | null>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const handleClickOutside = (event: { target: any; }) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !dropdownToggleRef.current?.contains(event.target)) {
        setIsMenuVisible(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  },        []);

  const loginComponent: () => JSX.Element | null = () => {
    if (props.simplified === true) return null;
    if (props.isAuthenticated) {
      return (
        <div className={styles.EcosystemHeader__EcosystemHeaderWrapper__Login}>
          <span data-test-id={'homes'} className={styles.EcosystemHeader__EcosystemHeaderWrapper__MiddleLink}>
              <a href="/home">Home</a>
            </span>
            <span data-test-id={'releases'} className={styles.EcosystemHeader__EcosystemHeaderWrapper__MiddleLink}>
              <a href="/releases">Releases</a>
            </span>
            <span data-test-id={'news'} className={styles.EcosystemHeader__EcosystemHeaderWrapper__MiddleLink}>
              <a href="/news">News</a>
            </span>
            <span data-test-id={'userEmail'} className={styles.EcosystemHeader__EcosystemHeaderWrapper__MiddleLink}>
              <a href="/my-account">My Account</a>
            </span>
          <button data-test-id={'logout'} id={'logout'} onClick={props.logout} className={styles.logout}>Logout</button>
        </div>
      );
    }

    return null;
  };

  const linksComponent: () => JSX.Element | null = () => {
    if (props.simplified === true) return null;
    if (props.isAuthenticated) {
      return (
        <div className={classNames(styles.EcosystemHeader__EcosystemHeaderWrapper__links, { [styles.show]: isMenuVisible })}>
          {props.headerLinks?.map(headerLink => (
            <a className={styles.EcosystemHeader__EcosystemHeaderWrapper__HeaderLink} href={headerLink.href} key={headerLink.text}>
              {headerLink.text}
            </a>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.EcosystemHeader}>
      <LayoutWrapper>
        <div className={styles.EcosystemHeader__EcosystemHeaderWrapper}>
          <a className={styles.EcosystemHeader__EcosystemHeaderWrapper__Logo} href="/">
            <EcosystemLogo title="GFK Logo" width={40} height={40} />
            <div className={styles['EcosystemHeader__EcosystemHeaderWrapper__Logo-text']}>
              <span className={styles['EcosystemHeader__EcosystemHeaderWrapper__Logo-text--orange']}>gfk</span>
              <span className={styles['EcosystemHeader__EcosystemHeaderWrapper__Logo-text--white']}>boutique</span>
            </div>
          </a>
          <div className={classNames(styles.dropdownContainer, { [styles.showMenu]: isMenuVisible })}>
            {(props.isAuthenticated && !props.simplified && windowWidth <= 380) && (
              <div ref={dropdownToggleRef} className={styles.dropdownToggle} onClick={() => setIsMenuVisible(!isMenuVisible)}>
                <div className={styles.dropdownToggleLine}></div>
                <div className={styles.dropdownToggleLine}></div>
                <div className={styles.dropdownToggleLine}></div>
              </div>
            )}
            <div ref={dropdownRef} className={`${styles.EcosystemHeader__EcosystemHeaderWrapper__links} ${isMenuVisible && window.innerWidth <= 890 ? styles.show : ''}`}>
              {linksComponent()}
              {loginComponent()}
            </div>
          </div>
        </div>
      </LayoutWrapper>
    </div>
  );
};

export default EcosystemHeader;
