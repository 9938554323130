
export enum PresentationMode {
    default,
    chartAxis,
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ProductType {
  Core = 'Core',
  Supplemental = 'Supplemental',
}

export enum ReleaseType {
  Monthly = 'Monthly',
  Flash = 'Flash',
  Weekly = 'Weekly',
}
