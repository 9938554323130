import { gql } from '@apollo/client';

// This file is auto generated, please see readme for how to generate
import { GetReleasesForClient, GetReleasesForClientVariables } from './types/GetReleasesForClient';

export type VariablesType = GetReleasesForClientVariables;
export type ResultType = GetReleasesForClient;

export const query = gql`
  query GetReleasesForClient(
      $skip: Int,
      $take: Int,
      $sortBy: String,
      $sortDirection: SortDirection,
      $releaseKey: Int,
      $deliverableKey: Int,
      $productCategoryKey: Int,
      $releaseType: ReleaseType,
  ) {
    releaseLogsForClient(skip: $skip, take: $take, sortBy: $sortBy, sortDirection: $sortDirection, releaseKey: $releaseKey, deliverableKey: $deliverableKey, productCategoryKey: $productCategoryKey, releaseType: $releaseType) {
      items {
        id
        release {
          publishedAt
          releaseType
        }
        productCategory {
          id
          name
        }
        packageKey
        packageName
        deliverable {
          id
          name
          type
        }
        file {
          id
          originalFilename
          uploadVersion
        }
      }
      count
    }
  }
`;
