import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { ResendTemporaryPassword, ResendTemporaryPasswordVariables } from './types/ResendTemporaryPassword';

export type VariablesType = ResendTemporaryPasswordVariables;
export type ResultType = ResendTemporaryPassword;

export const query = gql`
  query ResendTemporaryPassword(
    $email: String!
  ) {
    resendTemporaryPassword(
      email: $email
    )
  }
`;
