import { QueryStringStateDetails } from './types';
import { getCurrentQueryString } from './utilities';
export const batchUpdate = (...updates: QueryStringStateDetails<any>[]) => {
  const searchParams = new URLSearchParams(window.location.search);

  updates.forEach(({ value, key, converter, isBatchUpdate }) => {
    if (isBatchUpdate === false) {
      console.warn(`batchUpdate has received an update for ${key} without isBatchUpdate set to true.
      This can lead to unintended behavior as this state will update the history stack individually as well as with the batch update.
      It is recommended to pass isBatchUpdate as true to avoid individual history stack updates.`);
    }
    const stringValue = converter.toString(value);
    if (stringValue) {
      searchParams.set(key, stringValue);
    } else {
      searchParams.delete(key);
    }
  });
  const updatedQueryString = searchParams.toString();
  if (updatedQueryString !==  getCurrentQueryString()) {
    window.history.pushState({}, '', `?${updatedQueryString}`);
  }

};
