import React, { useState, useEffect } from 'react';
import styles from './pages/Ovn/Ovn.module.scss';
import { FaChevronCircleUp } from 'react-icons/fa';

export const ScrollToTopButton: React.FunctionComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  },        []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className={`${styles.scrollToTopButton} ${isVisible && styles.isvisible}`}
      onClick={handleClick}
    >
      <FaChevronCircleUp/>
    </button>
  );
};

export default ScrollToTopButton;
