import React, { ReactNode } from 'react';
import styles from './ContentPane.module.scss';

export interface Props {
  title: string;
  children: ReactNode;
}

const ContentPane: React.FC<Props> = (props) => {
  return (
    <section className={styles.ContentPane}>
      <header className={styles.ContentPane__Title}>
        <span>{props.title}</span>
      </header>
      {props.children}
    </section>
  );
};

export default ContentPane;
