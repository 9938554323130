import Auth, { CognitoUser } from '@aws-amplify/auth';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';
import React, { useEffect, useMemo, useState } from 'react';
import { IconContext } from 'react-icons';
import { ApolloProvider } from '@apollo/client';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import Authenticator from './components/auth/authenticator';
import MyAccount from './components/pages/MyAccount/MyAccount';
import NotFound from './components/pages/NotFound/NotFound';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import EcosystemFooter from './components/system/EcosystemFooter/EcosystemFooter';
import EcosystemHeader from './components/system/EcosystemHeader/EcosystemHeader';
import { getConfig, isProduction } from './config';
import { createApolloClient } from './graphql/client';
import LayoutWrapper from './components/system/LayoutWrapper/LayoutWrapper';
import Dashboard from './components/pages/Dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import DeliverableDownload from './components/pages/DeliverableDownload/DeliverableDownload';
import { ArchiveDirectDownload } from './components/ArchiveDownload/ArchiveDownload';
import Home from './components/pages/Home/Home';
import News from './components/pages/Ovn/News';

// tslint:disable-next-line:function-name
function App() {
  const [currentUser, setCurrentUser] = useState<CognitoUser | undefined>(undefined);
  const [currentUserEmail, setCurrentUserEmail] = useState<string>('');

  const config = getConfig();

  Auth.configure({
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolClientId,
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: config.cookieExpiryDays,
      secure: isProduction(),
    },
  } as AuthOptions);

  const onAuthStateChange = () => {
    if (!currentUser) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (!currentUser) {
            setCurrentUser(user);
            setCurrentUserEmail(user.attributes.email);
          }
        })
        .catch(() => console.log('Ignoring auth, user is not authenticated'));
    }
  };

  useEffect(() => {
    if (!currentUser) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (!currentUser) {
            setCurrentUser(user);
            setCurrentUserEmail(user.attributes.email);
          }
        })
        .catch(() => console.log('Ignoring auth, user is not authenticated'));
    }
  },        [currentUser, setCurrentUser]);

  const location = useLocation();

  const showSimpilifiedHeader = () => {
    return !!['/privacy-policy'].find(route => location.pathname.includes(route));
  };

  const handleUserEmailChange = (email: string): void => {
    setCurrentUserEmail(email);
  };

  const handleLogout = () => {
    // tslint:disable-next-line: no-floating-promises
    Auth.signOut();
    setCurrentUser(undefined);
  };

  const client = useMemo(() => createApolloClient(), []);

  return (
    <ApolloProvider client={client}>
      <div className={styles.EcosystemApp}>
        <EcosystemHeader
          isAuthenticated={!!currentUser}
          email={currentUserEmail}
          logout={handleLogout}
          simplified={showSimpilifiedHeader()}
        />
        <LayoutWrapper>
          <Switch>
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/not-found" component={NotFound} />
            <Switch>
              <IconContext.Provider value={{ size: '1.5em', style: { verticalAlign: 'middle', margin: '0 5 0 0' } }}>
                <Authenticator onAuthStateChange={onAuthStateChange}>
                  {currentUser && currentUserEmail && (
                    <>
                      <Route path="/" exact>
                        <Redirect to="/home" />
                      </Route>
                      <Route path="/news" exact>
                        <News />
                      </Route>
                      <Route path="/news/:sectorKey">
                        <News />
                      </Route>
                      <Route path="/home" exact>
                        <Home />
                      </Route>
                      <Route path="/releases" exact>
                        <Dashboard />
                      </Route>
                      <Route path="/releases/:productCategoryKey">
                        <Dashboard />
                      </Route>
                      <Route
                        path="/my-account"
                        render={props => <MyAccount {...props} onUserEmailChange={handleUserEmailChange} />}
                      />
                      <Route path="/release/:releaseKey/deliverable/:deliverableKey">
                        <DeliverableDownload />
                      </Route>
                      <Route path="/archive/:objectId">
                        <ArchiveDirectDownload />
                      </Route>
                    </>
                  )}
                </Authenticator>
              </IconContext.Provider>
            </Switch>
            <Redirect from="*" to="/not-found" />
          </Switch>
        </LayoutWrapper>
        <EcosystemFooter />
      </div>
    </ApolloProvider>
  );
}

export default App;
