import React, { FormEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import Input from 'src/components/system/Input/Input';
import Ovn from './Ovn';
import styles from './Ovn.module.scss';

interface NewsParams {
  sectorKey?: string;
}

const News: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const { sectorKey } = useParams<NewsParams>();

  const onSearchChange = (e: FormEvent<HTMLInputElement>) => {
    const searchString: string  = e.currentTarget.value;
    setSearch(searchString);
  };

  return (
    <>
      <h1>Overnight News</h1>
      <div>
        <div className={styles.search}>
          <Input type="search" key="search" id="search" placeholder="Search..." data-testid="search"
          onChange={onSearchChange} value={search} />
        </div>
        {(sectorKey !== undefined) ?
        <Ovn search={search} sectorKeyFromHome={+sectorKey}/> : <Ovn search={search}/>}
      </div>

    </>
  );
};

export default News;
