import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { useCustomHeader } from '../../hooks';

export type CustomHeaderProps = ReactDatePickerCustomHeaderProps & {
  minimumDate: Date | null | undefined;
  maximumDate: Date | null | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  isStart: boolean;
};

export const CustomHeader: React.FC<CustomHeaderProps> = ({
  minimumDate,
  maximumDate,
  startDate,
  endDate,
  monthDate,
  decreaseMonth,
  increaseMonth,
  changeMonth,
  changeYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  isStart,
}) => {
  const { availableMonths, availableYears, currentMonth, minMaxRange, currentYear } = useCustomHeader({
    monthDate,
    minimumDate,
    maximumDate,
    startDate,
    endDate,
    isStart,
  });

  const months = availableMonths.map((date: Date) => (
    <option key={date.getMonth()} value={date.getMonth()}>
      {format(date, 'MMMM')}
    </option>
  ));

  const years = availableYears.map((date: Date) => (
    <option key={date.getFullYear()} value={`${date.getFullYear()}`}>
      {date.getFullYear()}
    </option>
  ));

  const changeYearHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    changeYear(Number(event.target.value));

    const dateBySelectedMonth = new Date(Number(event.target.value), monthDate.getMonth());
    const isBeforeStartDate = startDate && dateBySelectedMonth < startDate;
    const isAfterEndDate = endDate && dateBySelectedMonth > endDate;
    const isBeforeMiminumDate = minimumDate && dateBySelectedMonth < minimumDate;
    const isAfterMaximumDate = maximumDate && dateBySelectedMonth > maximumDate;

    if (
      isWithinInterval(dateBySelectedMonth, minMaxRange) ||
      isWithinInterval(endOfMonth(dateBySelectedMonth), minMaxRange)
    ) {
      return;
    }

    if (startDate && isBeforeStartDate) {
      return changeMonth(startDate.getMonth());
    }

    if (endDate && isAfterEndDate) {
      return changeMonth(endDate.getMonth());
    }

    if (minimumDate && isBeforeMiminumDate) {
      return changeMonth(minimumDate.getMonth());
    }

    if (maximumDate && isAfterMaximumDate) {
      return changeMonth(maximumDate.getMonth());
    }
  };

  return (
    <>
      {!prevMonthButtonDisabled && (
        <button
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          aria-label="Previous Month"
          onClick={decreaseMonth}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
            Previous Month
          </span>
        </button>
      )}
      <div className="react-datepicker__current-month">{`${currentMonth} ${currentYear}`}</div>
      {!nextMonthButtonDisabled && (
        <button
          type="button"
          className="react-datepicker__navigation react-datepicker__navigation--next"
          aria-label="Next Month"
          onClick={increaseMonth}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span>
        </button>
      )}
      <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--select">
        <div
          className={`react-datepicker__month-dropdown-container
          react-datepicker__month-dropdown-container--select`}
        >
          <select
            value={monthDate.getMonth()}
            className="react-datepicker__month-select"
            onChange={e => changeMonth(Number(e.target.value))}
            data-test-id="month-select"
          >
            {months}
          </select>
        </div>
        <div
          className={`react-datepicker__year-dropdown-container
          react-datepicker__year-dropdown-container--select`}
        >
          <select
            value={monthDate.getFullYear()}
            className="react-datepicker__year-select"
            data-test-id="year-select"
            onChange={changeYearHandler}
          >
            {years}
          </select>
        </div>
      </div>
    </>
  );
};
