import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { GetClient } from './types/GetClient';

export type ResultType = GetClient;

export const query = gql`
  query GetClient
    {
    client {
      id
      title
      firstName
      lastName
      emailAddress
      contactNumber
      isSubscribedToEmails
      isSuspended
      account {
        id
        name
        accountGroupKey
        active
      }
      createdOn
      createdBy {
        id
        firstName
        lastName
        emailAddress
      }
      modifiedOn,
      modifiedBy {
        id
        firstName
        lastName
        emailAddress
      }
    }
  }`;
