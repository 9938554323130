import React from 'react';
import { Accordion } from 'src/components/system/Accordion/Accordion';
import ContentPane from '../../ContentPane/ContentPane';
import styles from './PrivacyPolicy.module.scss';
import Table from 'src/components/system/Table/Table';

const PrivacyPolicy: React.FC = () => {
  const handleRedirectToSection = (sectionNumber: string) => {
    const sectionTitle = document.getElementById(sectionNumber);
    sectionTitle?.scrollIntoView();
  };

  return (
    <div className={styles.PrivacyPolicy}>
      <ContentPane title="Privacy Policy">
        <Accordion title="1. Overview" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>GfK is a German-based company and trusted partner for market research, business consulting and
                prescriptive data analytics. This privacy notice provides information on how GfK generally collects and processes
                your personal data when you interact with us. We provide additional privacy notices where necessary, for example
                if you take part in one of our panels or particular market research activities. Personal data means any information
                about you which can be used to identify you as an individual. This excludes information where your personal data
                has been removed (anonymous data).
                We regularly review our privacy notice and therefore encourage you to refer to this privacy notice on a regular
                basis. GfK consists of the legal
                entities listed <a href="https://www.gfk.com/en/entity-list" target="_blank" rel="noopener noreferrer">here</a>,
                together forming the “GfK Group”. GfK SE is the entity responsible for this privacy notice and it is issued on
                behalf of the GfK Group.<br /><br />
                <p className={styles['PrivacyPolicy__padding']}>
                  GfK SE<br />Sophie-Germain-Strasse 3-5<br />90443 Nuremberg<br />Germany<br /><br />
                  Entered in the Commercial Register at the District Court: Nuremberg, HRB 25014<br />
                </p>
                When referring to “GfK”, “we”, “us”, or “our” in this privacy notice, we are referring to the relevant entity of the
                GfK Group responsible for processing your personal data. We will inform you which GfK entity is the controller of your
                personal data, when we engage with you as a data subject.<br /><br />
                GfK has appointed data protection officers (DPOs) as indicated in the list of GfK affiliates.If you have any questions
                about this privacy notice or our data protection practices, please contact <a href="mailto:dpo@gfk.com">dpo@gfk.com</a>.
              </p><br />
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}> 1.1 Categories of personal data</b>
                </h5>
                We collect, use, store, process, and transfer different types of personal data, depending on the collection purpose.
                We only collect data categories
                necessary, for achieving a specific purpose. Personal data we collect includes, but is not limited to:<br /><br />
                <b>Contact Data: </b>first name, maiden name, surname, company name, job title / role, company email address and phone
                number, company billing address, delivery address, email address and telephone numbers<br /><br />
                <b>Employement Application Data: </b>information we collect from you if you apply for a job at GfK such as, data concerning
                your professional life, information included in your CV, graduation or other relevant certificates,
                employers' references<br /><br />
                <b>Financial Data: </b>bank account and payment card details, details about payments to you or received from you, as well as
                details of products and services you have purchased from us<br /><br />
                <b>Internet-log Data: </b>internet protocol (IP) address, browser type and version, time zone setting and location,
                browser plug-in types and versions, operating system and platform and other technology on the devices you use to access
                our websites, information about how you use our website,
                products and services, device identifiers such as mobile advertising IDs, cookie IDs (please also
                refer to our cookie policy)<br /><br />
                <b>User Account Data: </b>login credentials (username or similar identifier, password/password hash), preferences,
                any feedback you have provided us with<br /><br />
                <b>Marketing and Communications Data: </b>your preferences in receiving marketing materials from us and from third parties,
                your preferred communication channels<br /><br />
                <b>Research Data: </b>refers to all types of personal data we collect from you in the course of your participation in our
                research activities. Research Data includes the following categories, as detailed the privacy policies
                and consent forms for the respective market research activity:<br />
              </p>
              <p className={styles['PrivacyPolicy__padding']}>
                <b>Survey Data: </b>your answers to online, paper and pencil or telephone surveys including sociodemographic information
                such as title, date of birth, age, gender, information regarding educational and income levels, occupation, marital status,
                number of children in household, etc.<br /><br />
                <b>Media Measurement Data: </b>information regarding your TV and streaming media consumption, your overall usage of the
                internet, streaming and social media platforms and other (online) media channels, as well as your digital
                devices in general<br /><br />
                <b>Shopping Behavior data: </b>information regarding your household purchases<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                Research Data may include or reveal Sensitive Data as explained below.<br /><br />
                <b>Sensitive Data </b>refers to special categories of personal data that reveals racial or ethnic origin, political
                opinions, religious or philosophical beliefs, trade union membership,
                genetic data, biometric data for the purpose of uniquely identifying a natural person, health, sexual orientation
                or aspects of an individual's sex life, genetic data, or biometric data.
              </p><br />
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>1.2 Personal data of children</b>
                </h5>
                We only process children's personal data to the extent permitted by law, with and with prior consent of the legal
                guardian where necessary. If we become aware that personal data of a child has inadvertently been collected, we will
                delete such data without undue delay.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>1.3 What happens if you dont want to provide personal data?</b>
                </h5>
                Participation in our market research is always voluntary. You may stop participating and/or providing personal data
                at any time. If you withdraw your consent to the processing of your personal data or terminate your membership in a
                panel, you will no longer be eligible for studies and consequently,
                any future incentives and compensations we may offer to members and other participants.<br /><br />
                If the processing of your personal data is necessary for the performance of a contract to which you are party to, and
                you fail to provide the necessary information, we may cancel our services under the contract. In this case, we will
                notify you in advance.<br /><br />
                If the collection of your personal data is necessary to conclude an employment relationship with you and you fail
                to provide that data when requested, we may be unable to process your application.<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="2. Personal data we collect from you in our market research activities" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                In the following section we explain typical situations in which we collect personal data from or about you when you
                participate in our market research.<br /><br />
                We collect and process Research Data from you when you participate in our market research activities such as answering
                surveys and registering for research panels.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}> 2.1 Members of GfK market research panels</b>
                </h5>
                We offer participation in different panels for which you may register upon invitation.<br /><br />
                If you take part in a panel, we will collect your Contact Data. During your participation you will be asked to
                participate in research projects and GfK collects
                Survey Data and Behavioral measurement data. If the research is internet-based, we collect Internet Log-Data as well.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}> 2.2 Respondents to GfK surveys outside of our panels</b>
                </h5>
                You may be invited to participate in our market research surveys even if you are not registered as a participant
                with GfK.<br />
                In any of the following scenarios, GfK is responsible as a data controller for the collection and processing of
                Internet Log-Data, whereas other parties
                may be responsible as controllers for other personal data concerning you.<br />
              </p>
              <p className={styles['PrivacyPolicy__sub-subheading-padding']}>
                <h6>
                  <b> 2.2.1 Cooperation with other market research companies</b>
                </h6>
                If you are registered as a participant with other market research companies or panel operators, you could
                occasionally receive invitations to surveys
                conducted by GfK. In this case the other company is responsible as controller for your personal data, like
                name, address or email. If, in
                exceptional cases, there is a possibility that Research Data collected by us could personally identify you
                as an individual, we will inform you
                accordingly in advance.<br />
              </p>
              <p className={styles['PrivacyPolicy__sub-subheading-padding']}>
                <h6>
                  <b> 2.2.2 Personal Data provided to us by our clients</b>
                </h6>
                We may receive your Contact Data from a client company who commissioned us to conduct a survey on their behalf
                with a specific group of people. For example,
                a client could send us a list of its end customers for this purpose. In this case, our customer is controller
                of both your Contact Data and the data that
                is collected from you during the survey. In such cases, we will disclose to you the identity of the customer who
                provided your Contact Data to us.<br />
              </p>
              <p className={styles['PrivacyPolicy__sub-subheading-padding']}>
                <h6>
                  <b> 2.2.3 Randomly selected participants in telephone surveys</b>
                </h6>
                In some countries, we may use telephone numbers from public directories and/or numbers that we automatically
                generated based on numbers from public sources,
                using industry approved algorithms. Consequently, we may call your number even if you did not make it publicly
                available.<br /><br />
                If you receive a phone call from GfK or one of our business partners and are asked to answer questions, for example,
                about certain brands and products, this does
                not necessarily mean that we have any of your personal data other than your telephone number in our possession.<br /><br />
                Your answers to telephone surveys will be treated anonymously and not stored together with your telephone number. If
                you are randomly selected to take part in a
                survey and receive a call from us but refuse to participate and want us to refrain from calling you in the future,
                please let us know, and we will add
                your number to a blocking list.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}> 2.3 Online Audience Measurement</b>
                </h5>
                We collect Media Measurement Data if you take part in our audience measurement panels.<br />
                We do so by hardware or software-based web-tracking and metering technologies such as tracking apps, browser
                add-ons, TV meters and proprietary internet routers, cookies
                and similar internet technologies like digital device fingerprinting.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}> 2.4 Shopping Behavior Data</b>
                </h5>
                We collect Shopping Behavior Data if you take part in our consumer panels.<br /><br />
                We do this with the use of mobile apps and devices with which you scan barcodes of products that you purchased
                or take pictures of sales receipts,
                or by simply asking you to enter information regarding your purchases into online forms.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}> 2.5 How we use your personal data as a market
                  research participant</b>
                </h5>
                We use your Contact Data to directly communicate with you, for example, to keep you informed about our terms and
                policies or panel rules as well as incentives
                or prize draws / sweepstakes we offer to participants through email newsletters, to invite you to surveys and other
                panel activities and to select participants for
                specific surveys based on their sociodemographic profile.<br /><br />
                In general, we process your Research Data in such a way that they do not allow recipients to identify you as an
                individual. Typically, we combine the
                Research Data of several respondents and generate anonymous aggregated data.<br /><br />
                In some cases, we may report the Research Data on a participant's level, using an internally assigned pseudonym
                (a number or code). Recipients are not
                able to identify you based on these pseudonyms.<br /><br />
                If there is a possibility that a recipient of the Research Data can identify you as an individual, either directly
                or by combining it with other
                data, we will inform you in advance and obtain your consent if required.<br /><br />
                We use Media Measurement and Shopping Behavior Data to enrich collected Research Data in order to provide better
                insights to our clients.<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion titleId="3.2" title="3. Personal data we collect from you outside of our market research activities" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>3.1 Personal data provided by you through direct interactions</b>
                </h5>
                You may provide us with your Contact Data, User Account Data, and Financial Data by filling in forms or by corresponding
                with us by post, phone, email or otherwise.
                This includes personal data you provide when you:<br /><br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li>visit or create a user account on a GfK website,</li>
                  <li>request or use or products or services,</li>
                  <li>enter into a contract with us,</li>
                  <li>apply for a job or position with us,</li>
                  <li>subscribe to our services or publications,</li>
                  <li>request marketing material,</li>
                  <li>enter a competition or promotion or take part in a customer feedback survey,</li>
                  <li>give us feedback or contact us.</li>
                </ul><br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>3.2 Publicly available sources</b>
                </h5>
                We may collect additional information (Contact Data) about you from other sources if you are a
                representative of an interested party or a prospect from publicly available sources or existing client. Such sources
                are mainly, but not limited to,
                internet sources such as professional networks like linked.in and XING but also respective company websites and possibly
                other websites.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>3.3 What we use our personal data for</b>
                </h5>
                We will only use your personal data for the purposes for which we collected it and for and for purposes compatible with
                the original collection purposes.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>3.4 Communication with you as a business partner</b>
                </h5>
                We process your personal data if you are a potential customer of our products, an existing customer or a service provider
                for the establishment and performance of our business relationship with you.<br /><br />
                If you register on our website to receive specific content, newsletters, or to attend webinars, or you contact us by email
                or otherwise, we will process your Contact Data and any information that you submit through our web
                forms or send us by email for the following purposes:<br /><br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li>in order to communicate with you about our services,</li>
                  <li>to let you know about our policies and terms,</li>
                  <li>to respond to your requests,</li>
                  <li>to invite you to participate in customer feedback surveys,</li>
                  <li>to organize events that you have registered for.</li>
                </ul><br /><br />
                We process your Contact Data, Financial Data, Internet Log-Data, and User Account Data to deliver our services to you and
                to better understand how you use our solutions.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>3.5 Marketing consent</b>
                </h5>
                If you sign up or otherwise opt-in to receive invitations to our free webinars and events, thought-leadership articles, or
                product announcement emails, we will ask for your consent to use your email address for this purpose.<br /><br />
                We may use your Contact Data, Internet Log-Data, and User Account Data to better understand what may be of interest to you
                and how this content is best presented to you.<br /><br />
                We will obtain your opt-in consent before sharing your personal data with any third party for
                marketing purposes.<br /><br />
                You may unsubscribe from marketing communications at any time by clicking the link to 'unsubscribe' at the bottom of any
                marketing email we have sent to you.<br />
                If you opt out of receiving such marketing communications, personal data provided to us as a result of a product/service
                purchase, warranty registration, product/service experience or other transactions will still be processed.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>3.6 Job applicants</b>
                </h5>
                If you apply for a position at GfK we collect Contact Data and Employment Application Data from you in order to make
                decisions about the possible conclusion of an employment contract.<br />
                Should we require further categories of your personal data in the course of the job application process or intend
                to use this data for purposes other than those specified above, we will provide you all
                information in advance as required by law through an additional privacy notice.<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion titleId="4" title="4. Internet Log-Data" nested >
          <section className={styles.PrivacyPolicy__section} >
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                If you interact with our website or software applications including cloud based or online applications, we will
                automatically collect Internet Log-Data about your equipment, browsing actions and patterns.<br /><br />
                We use Internet Log-Data to enhance the users' experience and improve the performance, user friendliness and security
                of the surveys and other solutions, and for quality assurance purposes.<br /><br />
                We may also use Internet Log-Data to meet our internal and external audit requirements, information security purposes,
                or to protect or enforce our rights, privacy, safety, or property, or those of other persons.
                This includes the prevention and detection of fraud, disruptions to our services or IT systems. We may be required to use
                and retain personal data for legal and compliance reasons, such as the prevention, detection, or investigation of a crime,
                loss or IT security incident.<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="5. Cookies and similar technologies" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                We collect personal data about you by using cookies, server logs and other similar technologies if you interact with our
                website or software applications including cloud based or online applications. We may also receive such data if you visit
                other websites by means of our cookies and similar technologies.<br /><br />
                You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If
                you disable or refuse cookies, please note that some parts of our websites may become inaccessible or may not function
                properly.<br /><br />
                For more details please go to <a href="https://www.gfk.com/cookie-notice" target="_blank" rel="noopener noreferrer">
                GfK cookie notice</a>.<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="6. Third parties or publicly available sources" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                We may obtain personal data about you from third parties and public sources. In these cases, we will inform you
                accordingly within a month upon receipt of your personal data or, if the data is intended for communication with
                you, we will inform you with our first communication
                to you. As part of the communication we will disclose the sources from which we obtained your data and provide you
                with all information required by the applicable privacy law. This includes which data specifically we obtained from
                which exact sources and how we intend to use it.
                In appropriate cases we will obtain your prior consent before we collect personal data concerning you from other
                sources.<br /><br />
                In some instances, GfK's clients are a source of personal data concerning you. For example, advertising companies
                or social media networks may provide us with information regarding your exposure to online advertisements or media.
                This is used by us to enrich this data with information
                that we previously collected from you as a market research participant, in order to prepare anonymous, aggregated
                statistics that clients then use for their business purposes.<br /><br />
                We also process personal data collected by third parties using third party crawling and analytics tools such as
                Brandwatch (www.brandwatch.com) that archive social media data, which have been published in open profiles or
                channels on social media networks or platforms like Facebook,
                Twitter, Instagram , public online forums or rating portals of online-shops (e.g. eBay or Amazon). GfK may use
                information that you have made public on social media platforms and other websites to provide its clients with aggregated
                reports and links relating to public information in a systematic manner.<br /><br />
                Please see also sec.<a href="#3.2" onClick={() => handleRedirectToSection('3.2')}> 3.2 “Publicly available sources"</a>.
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="7. Legal bases of processing" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                We will only process your personal data for the purposes described above. We process your personal data as
                permitted by law:<br /><br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li>with your consent for a specific purpose: For example, we typically obtain your consent when conducting
                    our market research activities;</li>
                  <li>for preparation or performance of a contract with you, for example, if you are party to a contract as a
                    client or an employee of GfK;</li>
                  <li>to comply with legal obligations, for example, if we are required to respond to requests by public authorities
                    or in order to comply with tax law;</li>
                  <li>if necessary, to protect your vital interests, i.e., in life-threatening situations;</li>
                  <li>if necessary, for the public interest, for example when we conducti scientific research in cooperation with or
                    on behalf of universities;</li>
                  <li>if necessary, for purposes of our legitimate interest (or those of a third party) and your interests and
                    fundamental rights and freedoms do not override those interests. For example, we base our processing on our
                    legitimate interests when engaging our clients and protecting our systems.</li>
                </ul>
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="8. Personal Data Processing for compliance with legal obligations" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                We may be required to use and retain personal data for compliance with legal obligations and statutory retention
                provisions, such as commercial and tax laws as well as our legal obligation to maintain the security of processing pursuant
                to applicable privacy laws (see for instance sec. <a href="#4" onClick={() => handleRedirectToSection('4')}>
                  4. Internet Log-data</a>)<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="9. Transfer and sharing of personal data" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                We may transfer your personal data to third parties on one or more of the legal bases and for the purposes as
                specified above. We require all recipients to ensure the security of your personal data and to treat it in accordance
                with applicable data protection laws.<br /><br />
                Sometimes, it will also be necessary that we transfer your personal data to countries outside the European Union and
                the European Economic Area (EEA). Any transfers of personal data into countries other than those for whom an adequacy
                decision regarding the level of data protection was made by the
                <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
                target="_blank" rel="noopener noreferrer">European Commission</a>,
                occur on the basis of contractual agreements using standard contractual clauses adopted by the European Commission
                or other appropriate safeguards in accordance with the applicable law.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>9.1 Within GfK</b>
                </h5>
                Your personal data may be transferred to one or more GfK Group affiliated companies as needed for data processing,
                storage, analysis, enrichment, appending, to provide you with access to our services or customer support or otherwise
                fulfill the purposes for which we retained the data.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>9.2 Service providers and partners of GfK</b>
                </h5>
                If necessary and compatible with the purposes, we share personal data with:<br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li>Service providers, such as but not limited to hosting companies, call-centers and IT-service providers who are
                    contractually bound to use the data exclusively for our purposes and pursuant to our instructions.</li>
                  <li>Professional advisers and service providers acting as controllers in their own right or share responsibility
                    for the personal data processing with us, such as insurance companies and accounting services,</li>
                  <li>as well as with recipients who are subject to professional confidentiality, including lawyers, banks and
                    external auditors.</li>
                </ul><br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>9.3 Business transfers</b>
                </h5>
                We transfer data, including personal data, to a reasonable extent and if necessary in the course of any company
                reorganization, restructuring, merger or sale, or other transfer of assets. We ensure that the receiving party
                agrees to respect your personal data in a
                manner that is consistent with applicable data protection laws and the original purpose. We will continue to
                ensure the confidentiality of any personal data and give affected data subjects notice before personal data
                is transferred to another controller.<br />
              </p>
              <p className={styles['PrivacyPolicy__subheading-padding']}>
                <h5 className={styles['PrivacyPolicy__border-left']}>
                  <b className={styles['PrivacyPolicy__title-padding']}>9.4 Public bodies</b>
                </h5>
                We will only disclose your personal data to public bodies where this is required by law. GfK will, for example,
                 respond to requests from courts of law, law enforcement agencies, regulatory agencies, and other public and
                  government authorities, which may
                include authorities outside your country of residence.<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="10. Third party websites" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                Our websites as well as portals and registration pages for market research participants may contain links to
                websites that are not affiliated with, controlled,
                or managed by us. The policies and procedures we describe here do not apply to those websites. We are not
                responsible for the security or privacy of any data collected by these third parties. We suggest contacting
                those websites directly for information on their privacy policies.<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="11. Security of processing" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                GfK takes data security seriously. We apply appropriate levels of security to personal data transferred, stored
                or otherwise processed by GfK by means of implemented physical, electronic and administrative procedures to
                safeguard the data
                from accidental or unlawful destruction, deletion, loss, alteration, unauthorized disclosure and unauthorized
                access. Our information security policies and procedures are closely aligned with widely accepted
                international standards and are
                reviewed regularly and updated as necessary to meet our business needs, changes in technology and regulatory
                requirements. Access to your personal data is granted only to those personnel, service providers or GfK
                affiliates with a business need-to-know or who require it in order to perform their duties.<br /><br />
                In the event of a data breach containing personal data, GfK will follow all applicable data breach
                notification provisions.<br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="12. Data Storage and Retention" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected
                it for. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe
                there is a prospect of litigation in respect to our relationship with you. To determine the appropriate retention
                period for personal data, we consider the:<br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li>amount, nature and sensitivity of the personal data,</li>
                  <li>potential risk of harm from unauthorized use or disclosure of the personal data,</li>
                  <li>purposes for which we process your personal data and whether we can achieve those purposes through other
                    means, and</li>
                  <li>applicable legal, regulatory, tax, accounting, reporting or other such requirements.</li>
                </ul><br />
              </p>
            </ol>
          </section>
        </Accordion>

        <Accordion title="13. Your legal rights" nested>
          <section className={styles.PrivacyPolicy__section}>
            <ol className={styles.PrivacyPolicy__list}>
              <p>
                You may exercise your legal rights in relation to your personal data we are processing. GfK will respect your
                individual rights and will deal with your concerns adequately. Your rights include:<br /><br />
                <b>Right to withdraw consent:</b> where the processing of personal data is based on your consent, you may withdraw
                this consent at any time by following the procedures described in the respective consent form.<br /><br />
                <b>Right to rectification:</b> you may request the rectification of your personal data. We make reasonable efforts
                to keep personal data that are used on an ongoing basis, accurate, complete, current and relevant according
                to the most recent information available to us. In appropriate cases, we provide self-service internet portals
                where users have the possibility to review and rectify their personal data.<br /><br />
                <b>Right to restriction:</b> you may request us to restrict the processing of your personal data, if:<br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li>you contest the accuracy of your personal data for the period we need to verify the accuracy,</li>
                  <li>the processing is unlawful, and you request the restriction of processing rather than erasure of your
                  personal data,</li>
                  <li>we no longer need your personal data, but you require these to assert, exercise or defend a legal claim, or</li>
                  <li>you object to the processing while we verify whether our legitimate grounds take precedence over yours.</li>
                </ul><br /><br />
                <b>Right to access:</b> You can request information from us about your personal data to ensure we process it
                lawfully. You can also request a copy of the personal data you provided us and is undergoing processing, free of
                charge. However, we reserve the right to charge a reasonable fee for each additional copy you may request.<br /><br />
                <b>Right to transfer:</b> At your request, we will transfer your personal data to you or a third party, where
                technically feasible, provided that the processing is based on your consent or necessary for the performance
                of a contract.<br /><br />
                <b>Right to ensure:</b> You may request us to delete your personal data, where<br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li>the personal data is no longer necessary in relation to the purposes for which it was collected or
                    otherwise processed for;</li>
                  <li>you have a right to object further processing of your personal data (see below) and execute this
                    right to object to the processing;</li>
                  <li>the processing is based on your consent, you withdraw your consent and there is no other legal
                    ground for the processing;</li>
                  <li>the personal data has been unlawfully processed;</li>
                </ul><br /><br />
                unless the processing is necessary<br /><br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li>for compliance with a legal obligation which requires processing from us;</li>
                  <li>for statutory data retention requirements;</li>
                  <li>for the establishment, exercise or defense of legal claims.</li>
                </ul><br /><br />
                <b>Right to object:</b> You may object - at any time - to the processing of your personal data due to your
                situation, provided that the processing is not based on your consent but on our legitimate interests or those
                of a third party. In this event we shall no longer process your personal data,
                unless we can demonstrate compelling legitimate grounds and an overriding interest for the processing or for
                the establishment, exercise or defense of legal claims. If you object to the processing, please specify whether
                you wish the deletion of your personal data or the restriction of its processing by us.<br /><br />
                <b>Right to lodge a complaint:</b> In case of an alleged infringement of applicable privacy laws, you may lodge
                a complaint with the data protection supervisory authority in the country you live in or where the alleged
                infringement occurred.<br /><br />
                <b>Please note:</b><br />
                <ul className={styles['PrivacyPolicy__bullet-padding']}>
                  <li><b>Time period:</b> We will try to fulfill your request within 30 days. However, the period may be
                  extended due to specific reasons relating to the specific legal right or the complexity of your request,
                  in which case we will inform you accordingly.</li>
                  <li><b>Restriction of access:</b> In certain situations, we may not be able to give you access to all or
                  some of your personal data due to statutory provisions. If we deny your request for access, we will
                  advise you of the reason for the refusal.</li>
                  <li><b>What we may need from you:</b> We may need to request specific information from you to help us confirm
                  your identity and ensure your right to access your personal data (or to exercise any of your other rights)
                  can be granted. This is a security measure
                  to ensure that personal data is not disclosed to any person who has no right to receive it. We may also
                  contact you to ask you for further information in relation to your request to speed up our response.</li>
                  <li><b>Excercise your legal rights:</b> In order to exercise your legal rights, please contact us in writing
                  or text form, e.g. by email or letter. You may also address our Regional Data Protection Officers directly.</li>
                </ul><br />
              </p>
            </ol>
          </section>
        </Accordion>
        <br /><br />
        <Table columns={[]}>
          <tr>
            <th>REGION</th>
            <th>DATA PROTECTION OFFICER</th>
          </tr>
          <tr>
            <td>The Americas</td>
            <td>
              <a href="mailto:dpo.americas@gfk.com">dpo.americas@gfk.com</a><br />
              GfK Custom Research, LLC<br />
              Attn: Data Protection Officer<br />
              200 Liberty Street, 4th Floor<br />
              New York, 10281<br />
              USA<br />
              <a href="https://www.gfk.com/en-us/gfk-united-states-privacy-notice" target="_blank" rel="noopener noreferrer">
              GfK US privacy policy</a>
            </td>
          </tr>
          <tr>
            <td>Asia-Pacific & Middle East, Turkey, Africa (APAC & META)</td>
            <td>
              <a href="mailto:dpo-apacmeta@gfk.com">dpo-apacmeta@gfk.com</a><br />
              GfK Asia Pte Ltd<br />
              Attn: Data Protection Officer<br />
              Visioncrest Commercial 103 Penang Road #05-01/05<br />
              Singapore 238467<br />
              Singapore
            </td>
          </tr>
          <tr>
            <td>Europe (excluding the UK and Switzerland)</td>
            <td>
              <a href="mailto:dpo@gfk.com">dpo@gfk.com</a><br />
              GfK SE<br />
              Attn: William Hammond<br />
              Sophie-Germain-Strasse 3-5<br />
              90443 Nuremberg<br />
              Germany
            </td>
          </tr>
          <tr>
            <td>The United Kingdom</td>
            <td>
              <a href="mailto:DataProtectionOfficerGfKUK.LON@gfk.com">DataProtectionOfficerGfKUK.LON@gfk.com</a><br />
              GfK UK Limited<br />
              Attn: Data Protection Officer<br />
              1 Park Row<br />
              Leeds LS1 5AB<br />
              United Kingdom
            </td>
          </tr>
          <tr>
            <td>Switzerland</td>
            <td>
              <a href="mailto:dpo.ch@gfk.com">dpo.ch@gfk.com</a><br />
              GfK Switzerland AG<br />
              Attn: Data Protection Officer<br />
              Suurstoffi 18<br />
              Rotkreuz 6343<br />
              Switzerland
            </td>
          </tr>
        </Table>
      </ContentPane>
    </div>
  );
};

export default PrivacyPolicy;
