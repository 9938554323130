import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { GenerateArchiveDownloadUrl, GenerateArchiveDownloadUrlVariables } from './types/GenerateArchiveDownloadUrl';

export type VariablesType = GenerateArchiveDownloadUrlVariables;
export type ResultType = GenerateArchiveDownloadUrl;

export const query = gql`
  query GenerateArchiveDownloadUrl(
    $id: Int
    $objectId: Int
  ) {
    generateArchiveDownloadUrl(
      id: $id
      objectId: $objectId
    )
  }
`;
