import { gql } from '@apollo/client';

// This file is auto generated, please see readme for how to generate
import { GetCombinedReleases, GetCombinedReleasesVariables } from './types/GetCombinedReleases';

export type VariablesType = GetCombinedReleasesVariables;
export type ResultType = GetCombinedReleases;
/*
  Fetches Archives and ReleaseLogs
*/
export const query = gql`
query GetCombinedReleases(
  $skip: Int,
  $take: Int,
  $sortBy: String,
  $sortDirection: SortDirection,
  $productCategoryKey: Int,
  $packageTypes: [PackageType!],
  $releaseType: ReleaseType,
  $dateRangeStart: DateTime,
  $dateRangeEnd: DateTime,
  $packageKeys: [Int!],
) {
  combinedReleases(skip: $skip, take: $take, sortBy: $sortBy, sortDirection: $sortDirection, productCategoryKey: $productCategoryKey,
    packageTypes: $packageTypes, releaseType: $releaseType, dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd, packageKeys: $packageKeys) {
    archives {
      id
      releaseId
      objectId
      filepath
      filename
      publishedAt
      packageKey
      package {
        name
        frequency
        packageType
      }
      productCategory {
        id
        name
      }
    }
    releaseLogs {
      id
        release {
          id
          publishedAt
          releaseType
        }
        productCategory {
          id
          name
        }
        packageKey
        packageName
        isSupplemental
        deliverable {
          id
          name
          type
        }
        file {
          id
          originalFilename
          uploadVersion
        }
    }
    count
  }
}
`;
