import React, { useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import GridContainer from '../../system/GridContainer/GridContainer';
import Input from '../../system/Input/Input';
import Button from '../../system/Button/Button';
import Loading from '../../system/Loading/Loading';
import InlineAlert, { InlineAlertType } from '../../system/InlineAlert/InlineAlert';
import { getClientDetailsValidationSchema,
         getClientEmailValidationSchema,
         getVerificationCodeValidationSchema,
         getPasswordValidationSchema } from './validationSchema';
import * as getClient from '../../../graphql/getClient';
import * as editClient from '../../../graphql/editClient';
import styles from './MyAccount.module.scss';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody, ModalHeader } from 'reactstrap';
import classnames from 'classnames';

interface Props {
  onUserEmailChange?: (email: string) => void;
}

type CognitoUserData = {
  username: string;
  email_verified: boolean;
};

interface IEditClientVariables extends editClient.VariablesType {
  [index: string]: any;
}

type ClientDetails = Omit<IEditClientVariables, 'emailAddress'>;

type Alert = {
  type: InlineAlertType;
  message: string;
};

const initialClientDetails: ClientDetails = {
  title: '',
  firstName: '',
  lastName: '',
  contactNumber: '',
};

const MyAccount: React.FC<Props> = (props) => {
  const [alert, setAlert] = useState<Alert | undefined>(undefined);
  const [modalAlert, setModalAlert] = useState<Alert | undefined>(undefined);
  const [changePasswordAlert, setChangePasswordAlert] = useState<Alert | undefined>(undefined);
  const [isSavingPassword, setIsSavingPassword] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [accountName, setAccountName] = useState<String>('');
  const [clientDetails, setClientDetails] = useState<ClientDetails>(initialClientDetails);
  const [clientEmail, setClientEmail] = useState<string>('');
  const [cognitoUserData, setCognitoUserData] = useState<CognitoUserData | undefined>(undefined);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (!cognitoUserData) {
      Auth.currentAuthenticatedUser()
      .then((user) => {
        setCognitoUserData({
          username: user.attributes.email,
          email_verified: user.attributes.email_verified,
        });
      })
      .catch(() => {
        setAlert({
          type: InlineAlertType.DataError,
          message: 'Error retrieving account status',
        });
      });
    }
  });

  const toggleTab = (tab: React.SetStateAction<number>) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModalVisibility = () => {
    setAlert(undefined);
    setChangePasswordAlert(undefined);
    verificationCodeForm.resetForm();
    setIsModalVisible(!isModalVisible);
  };

  const confirmVerificationCode = async (verificationCode: string): Promise<any> => {
    return Auth.verifyCurrentUserAttributeSubmit('email', verificationCode);
  };

  const resendVerificationCode = (): void  => {
    verificationCodeForm.resetForm();
    setIsModalLoading(true);
    if (cognitoUserData?.username) {
      Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        setIsModalLoading(false);
        setModalAlert({
          type: InlineAlertType.Info,
          message: `New code sent to ${clientEmail}`,
        });
      })
      .catch((reason) => {
        setIsModalLoading(false);
        return (
          <InlineAlert type={InlineAlertType.DataError}><p>{`Error: ${reason.message}`}</p></InlineAlert>
        );
      });
    }
  };

  const handleConfirmVerificationCode = (): void => {
    setAlert(undefined);
    setChangePasswordAlert(undefined);
    setModalAlert(undefined);
    setIsModalLoading(true);
    confirmVerificationCode(verificationCodeForm.values.verificationCode)
    .then(() => {
      setCognitoUserData({
        username: (cognitoUserData && cognitoUserData.username) || '',
        email_verified: true,
      });
      setIsModalLoading(false);
      toggleModalVisibility();
      setAlert({
        type: InlineAlertType.Success,
        message: 'Email address confirmed.',
      });
    })
    .catch(() => {
      setIsModalLoading(false);
      setModalAlert({
        type: InlineAlertType.DataError,
        message: 'Code invalid. Please try again or choose resend code.',
      });
    });
  };

  const handleConfirmModalClosed = (): void => {
    setModalAlert(undefined);
    setIsModalLoading(false);
  };

  const isChangePasswordFormBlank = (): Boolean => {
    const { currentPassword, newPassword, confirmNewPassword } = changePasswordForm.values;
    if ((currentPassword === '') && (newPassword === '') && (confirmNewPassword === '')) {
      return true;
    }
    return false;
  };

  const handleChangePassword = (): void => {
    setIsSavingPassword(true);
    setAlert(undefined);
    setChangePasswordAlert(undefined);
    Auth.currentAuthenticatedUser()
    .then((user) => {
      return Auth.changePassword(user, changePasswordForm.values.currentPassword, changePasswordForm.values.newPassword);
    })
    .then(() => {
      setIsSavingPassword(false);
      changePasswordForm.resetForm();
      setChangePasswordAlert({
        type: InlineAlertType.Success,
        message: 'Password changed.',
      });
    })
    .catch((reason) => {
      setIsSavingPassword(false);
      const reasonCode: string = reason && reason.code;
      let explanation: string;

      switch (reasonCode) {
        case 'InvalidParameterException':
          explanation = 'New password fails to meet requirements';
          break;
        case 'NotAuthorizedException':
          explanation = 'Current Password incorrect';
          break;
        default:
          explanation = 'Something went wrong please contact your Client Success Manager';
          break;
      }

      setChangePasswordAlert({
        type: InlineAlertType.DataError,
        message: `Problem changing password. ${explanation}.`,
      });
    });
  };

  const { loading: loadingClient, error: errorLoadingClient } =
  useQuery<getClient.ResultType>(getClient.query, {
    onCompleted: (data) => {
      const { client } = data;
      setClientDetails({
        title: client.title,
        firstName: client.firstName,
        lastName: client.lastName,
        contactNumber: client.contactNumber,
      });
      setClientEmail(client && client.emailAddress);
      setAccountName(client && client.account && client.account.name);
    },
  });

  const [saveClient, { loading: isSavingClient }] = useMutation(editClient.query);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },        []);

  const saveDetails = (details: ClientDetails): void => {
    setAlert(undefined);
    setChangePasswordAlert(undefined);
    saveClient({ variables: {
      ...details,
      emailAddress: clientEmail,
      title: clientDetails.title,
    }})
    .then(() => {
      setClientDetails(details);
      setAlert({
        type: InlineAlertType.Success,
        message: 'Details saved.',
      });
    })
    .catch(() => setAlert({
      type: InlineAlertType.DataError,
      message: 'Unable to save details.',
    }));
  };

  const clientDetailsForm = useFormik({
    initialValues: clientDetails,
    validationSchema: getClientDetailsValidationSchema<ClientDetails>(),
    onSubmit: saveDetails,
    enableReinitialize: true,
  });

  const clientEmailForm = useFormik({
    initialValues: { emailAddress: clientEmail },
    validationSchema: getClientEmailValidationSchema,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const verificationCodeForm = useFormik({
    initialValues: { verificationCode: '' },
    validationSchema: getVerificationCodeValidationSchema,
    onSubmit: handleConfirmVerificationCode,
    enableReinitialize: true,
  });

  const changePasswordForm = useFormik({
    initialValues: { currentPassword: '', newPassword: '', confirmNewPassword: '' },
    validationSchema: getPasswordValidationSchema,
    onSubmit: handleChangePassword,
    enableReinitialize: true,
  });

  const getNavItemClasses = (tabNumber: number) => {
    return classnames({
      [styles['nav__navItem']]: true,
      [styles['nav__navItem--active']]: activeTab === tabNumber,
    });
  };

  const getNavLinkClasses = (tabNumber: number) => {
    return classnames({
      [styles['nav__navItem__navLink']]: true,
      [styles['nav__navItem__navLink--active']]: activeTab === tabNumber,
    });
  };

  const getPWDRequirementStyles = (pwd: string, type: string) => {
    const validators: { [key: string]: RegExp } = {
        "length": /^.{8,}$/,
        "lowercase": /[a-z]/,
        "uppercase": /[A-Z]/,
        "number": /\d/,
        "special": /[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`]/
    };

    const isValid = validators[type]?.test(pwd);

    return isValid ? styles.valid : styles.invalid;
  };

  const isSmallScreen = viewportWidth <= 768;

  if (loadingClient) return <Loading />;
  if (errorLoadingClient) {
    return <InlineAlert type={InlineAlertType.DataError}><p>Unable to retrieve account details</p></InlineAlert>;
  }

  return (
    <>
      <h1>My Account</h1>
      <div>
        <Nav tabs className={styles.nav}>
          <NavItem className={getNavItemClasses(1)}>
            <NavLink
              data-testid="changeDetailsLink"
              className={getNavLinkClasses(1)}
              onClick={() => { toggleTab(1); }}
            >
              Account Details
            </NavLink>
          </NavItem>
          <NavItem className={getNavItemClasses(2)}>
            <NavLink
              data-testid="changePasswordLink"
              className={getNavLinkClasses(2)}
              onClick={() => { toggleTab(2); }}
            >
              Change Password
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <h2 className={styles.accountName}>{ accountName }</h2>
          { (loadingClient || isSavingClient) && <Loading /> }
          { alert && <InlineAlert type={alert.type}><p>{alert.message}</p></InlineAlert> }
          <form onSubmit={clientDetailsForm.handleSubmit}>
            { isSmallScreen ?
            <GridContainer item={true} desktop="1" gap="small">
              <div>
                <Input id="First Name" name="firstName" label="First Name" type="text"
                      onChange={clientDetailsForm.handleChange} value={clientDetailsForm.values.firstName}></Input>
                {clientDetailsForm.errors.firstName ? <div className={styles.error}>{clientDetailsForm.errors.firstName}</div> : null}
              </div>
              <div>
                <Input id="lastName" name="lastName" label="Last Name" type="text"
                      onChange={clientDetailsForm.handleChange} value={clientDetailsForm.values.lastName}></Input>
                {clientDetailsForm.errors.lastName ? <div className={styles.error}>{clientDetailsForm.errors.lastName}</div> : null}
              </div>
              <div>
                <Input name="contactNumber" label="Phone Number"
                  onChange={clientDetailsForm.handleChange} value={clientDetailsForm.values.contactNumber as string} id={''}></Input>
                {clientDetailsForm.errors.contactNumber ?
                <div className={styles.error}>{clientDetailsForm.errors.contactNumber}</div> : null}
              </div>
              <div>
                <Input id="emailAddress" name="emailAddress" label="Username (email)"
                value={clientEmailForm.values.emailAddress} disabled></Input>
              </div>
              <br/>
              <div className={styles.buttonContainer}>
                <Button type="submit" testId="save">Save</Button>
              </div>
            </GridContainer> :
            <GridContainer item={true} desktop="2" gap="small">
            <div>
              <Input id="First Name" name="firstName" label="First Name" type="text"
                    onChange={clientDetailsForm.handleChange} value={clientDetailsForm.values.firstName}></Input>
              {clientDetailsForm.errors.firstName ? <div className={styles.error}>{clientDetailsForm.errors.firstName}</div> : null}
            </div>
            <div>
              <Input id="lastName" name="lastName" label="Last Name" type="text"
                    onChange={clientDetailsForm.handleChange} value={clientDetailsForm.values.lastName}></Input>
              {clientDetailsForm.errors.lastName ? <div className={styles.error}>{clientDetailsForm.errors.lastName}</div> : null}
            </div>
            <div>
              <Input name="contactNumber" label="Phone Number"
                  onChange={clientDetailsForm.handleChange} value={clientDetailsForm.values.contactNumber as string} id={''}></Input>
              {clientDetailsForm.errors.contactNumber ?
              <div className={styles.error}>{clientDetailsForm.errors.contactNumber}</div> : null}
            </div>
            <div>
              <Input id="emailAddress" name="emailAddress" label="Username (email)"
              value={clientEmailForm.values.emailAddress} disabled></Input>
            </div>
            <div className={styles.buttonContainer}>
              <Button type="submit" testId="save">Save</Button>
            </div>
          </GridContainer>
          }
          </form>
        </TabPane>
        <TabPane tabId={2}>
          <GridContainer item={true} desktop="2" gap="small">
            <div className={styles.changePassword}>
              { changePasswordAlert && <InlineAlert type={changePasswordAlert.type}><p>{changePasswordAlert.message}</p></InlineAlert> }
              { isSavingPassword && <Loading /> }
              <form onSubmit={changePasswordForm.handleSubmit}>
                <div>
                  <GridContainer item={true} desktop="1" gap="small">
                    <div>
                      <Input type="password" id="currentPassword" name="currentPassword" label="Current Password"
                              onChange={changePasswordForm.handleChange} onBlur={changePasswordForm.handleBlur}
                              value={changePasswordForm.values.currentPassword}></Input>
                      {changePasswordForm.touched.currentPassword && changePasswordForm.errors.currentPassword ?
                      <div className={styles.error}>{changePasswordForm.errors.currentPassword}</div> : null}
                    </div>
                    <div>
                      <Input type="password" id="newPassword" name="newPassword" label="New Password"
                              onChange={changePasswordForm.handleChange} onBlur={changePasswordForm.handleBlur}
                              value={changePasswordForm.values.newPassword}></Input>
                      {changePasswordForm.touched.newPassword && changePasswordForm.errors.newPassword ?
                    <div className={styles.error}>{changePasswordForm.errors.newPassword}</div> : null}
                    </div>
                    <div>
                      <Input type="password" id="confirmNewPassword" name="confirmNewPassword" label="Confirm New Password"
                              onChange={changePasswordForm.handleChange} onBlur={changePasswordForm.handleBlur}
                              value={changePasswordForm.values.confirmNewPassword}></Input>
                      {changePasswordForm.touched.confirmNewPassword && changePasswordForm.errors.confirmNewPassword ?
                    <div className={styles.error}>{changePasswordForm.errors.confirmNewPassword}</div> : null}
                    </div>
                    <div className={styles.buttonContainer}>
                      <Button type="submit" testId="savePassword">
                        Change Password
                      </Button>
                      {isChangePasswordFormBlank() ? null : <Button type="reset" onClick={changePasswordForm.resetForm} testId="cancelPasswordChange">
                        Cancel
                      </Button> }
                    </div>
                  </GridContainer>
                </div>
              </form>
            </div>
            <div className={styles.passwordCriteria}>
              <p>Your new password must contain:</p>
              <ul>
                <li className={changePasswordForm.touched.newPassword || changePasswordForm.values.newPassword !== '' ? getPWDRequirementStyles(changePasswordForm.values.newPassword, 'length') : ''}>A minimum of 8 characters</li>
                <li className={changePasswordForm.touched.newPassword || changePasswordForm.values.newPassword !== '' ? getPWDRequirementStyles(changePasswordForm.values.newPassword, 'lowercase') : ''}>At least one lowercase character</li>
                <li className={changePasswordForm.touched.newPassword || changePasswordForm.values.newPassword !== '' ? getPWDRequirementStyles(changePasswordForm.values.newPassword, 'uppercase') : ''}>At least one uppercase character</li>
                <li className={changePasswordForm.touched.newPassword || changePasswordForm.values.newPassword !== '' ? getPWDRequirementStyles(changePasswordForm.values.newPassword, 'number') : ''}>At least one number</li>
                <li className={changePasswordForm.touched.newPassword || changePasswordForm.values.newPassword !== '' ? getPWDRequirementStyles(changePasswordForm.values.newPassword, 'special') : ''}>At least one special character</li>
              </ul>
            </div>
          </GridContainer>
        </TabPane>
      </TabContent>
        <Modal data-testid="confirmEmailModal" size="lg" fade={false} backdrop={true} isOpen={isModalVisible}
          toggle={toggleModalVisibility} onClosed={handleConfirmModalClosed}>
          <ModalHeader toggle={toggleModalVisibility}>Confirm Email</ModalHeader>
          <ModalBody>
            { isModalLoading && <Loading/> }
            { modalAlert ?
              <InlineAlert type={modalAlert.type}>
                <p>{modalAlert.message}</p>
              </InlineAlert> : null }
            <form onSubmit={verificationCodeForm.handleSubmit}>
              <GridContainer item={true} desktop="1" gap="standard">
                <div>
                  <p>
                    { `Please check your email (${clientEmail}) for your confirmation code, then enter it below:` }
                  </p>
                </div>
                <div>
                  <Input id="verificationCode" name="verificationCode" label="Code"
                         onChange={verificationCodeForm.handleChange} value={verificationCodeForm.values.verificationCode}></Input>
                  { verificationCodeForm.errors.verificationCode ?
                    <div className={styles.error}>{verificationCodeForm.errors.verificationCode}</div> : null }
                </div>
                <div></div>
                <div className={styles.buttonContainer}>
                  <Button type="submit" testId="confirmVerificationCode">
                    Confirm
                  </Button>
                  <Button type="button" onClick={resendVerificationCode} testId="resendVerificationCode">
                    Resend code
                  </Button>
                </div>
              </GridContainer>
            </form>
          </ModalBody>
        </Modal>
    </>
  );
};

export default MyAccount;
