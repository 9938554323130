import { gql } from '@apollo/client';
// This file is auto generated, please see readme for how to generate
import { GetSectors } from './types/GetSectors';

export type ResultType = GetSectors;

export const query = gql`
  query GetSectors {
    sectors {
      id
      name
    }
  }`;
