import { format } from 'date-fns';
import { SubscriptionDeliverableType, SubscriptionFrequency } from '../graphql/globalTypes';

export type EnumType = typeof SubscriptionFrequency | typeof SubscriptionDeliverableType;

export function formatEnum<T extends EnumType>(enumType: T, enumValue: T[keyof T]) {
  const value = enumValue as unknown;

  switch (enumType) {
    case SubscriptionDeliverableType:
      const subscriptionDeliverableType = value as SubscriptionDeliverableType;
      switch (subscriptionDeliverableType) {
        case SubscriptionDeliverableType.Notes:
          return 'Notes';
        case SubscriptionDeliverableType.Data:
          return 'Data';
        case SubscriptionDeliverableType.DataAndNotes:
          return 'Data & Notes';
        default:
          const neverValue: never = subscriptionDeliverableType;
          throw new Error(`Invalid subscription deliverable type provided: ${neverValue}`);
      }
    case SubscriptionFrequency:
      const subscriptionFrequency = value as SubscriptionFrequency;
      switch (subscriptionFrequency) {
        case SubscriptionFrequency.Weekly:
          return 'Weekly';
        case SubscriptionFrequency.Biweekly:
          return 'Biweekly';
        case SubscriptionFrequency.Monthly:
          return 'Monthly';
        default:
          const neverValue: never = subscriptionFrequency;
          throw new Error(`Invalid subscription frequency provided: ${neverValue}`);
      }
    default:
      throw new Error(`unknown enum type: ${enumType}`);
  }
}

export const formatDate = (date: Date | string) => format(new Date(date), 'dd MMM yyyy');
