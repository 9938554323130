import React from 'react';
import classnames from 'classnames';

import styles from './Table.module.scss';

interface TableProps {
  isFirstColumnSticky?: boolean;
  style?: React.CSSProperties;
}

const TableRow: React.FC<TableProps> = (props) => {
  const { isFirstColumnSticky, children, style = {} } = props;

  return (
    <tr className={classnames(isFirstColumnSticky && styles.Table__HeaderRow__stickyFirstColumn)} style={style}>
      {children}
    </tr>
  );
};

export default TableRow;
