import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import styles from './Home.module.scss';
import * as getProductCategoriesForClient from '../../../graphql/getProductCategoriesForClient';
import * as getArticles from '../../../graphql/getArticles';
import * as getAllProductCategories from '../../../graphql/getAllProductCategories';
import * as getClient from '../../../graphql/getClient';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import { Badge, Card, CardBody, CardLink, CardText, CardTitle, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'src/components/system/Button/Button';
import Loading from 'src/components/system/Loading/Loading';
import InlineAlert, { InlineAlertType } from 'src/components/system/InlineAlert/InlineAlert';
import { getConfig } from 'src/config';
import { FaArrowRight } from 'react-icons/fa';
import { CardContent, CardMedia, Typography } from '@material-ui/core';

import { queryStringKeys } from '../../ReleaseTable/ReleaseTable';
const Home: React.FC = () => {

  const [clientAccountGroupKey, setClientAccountGroupKey] = useState<number>();
  const [productCategories, setProductCategories] = useState<{id: number, name: String}[]>([]);
  const [articles, setArticles] = useState<any[]>([]);
  const [allProductCategories, setAllProductCategories] = useState<{id: number, name: String}[]>([]);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState<boolean>(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false);

  const hasClientQueryCalled = useRef<boolean>(false);

  const config = getConfig();

  const [clientQuery, clientQueryData] =
  useLazyQuery<getClient.ResultType>(getClient.query, {
    onCompleted: (data) => {
      const clientAGK = data!.client.account.accountGroupKey;
      setClientAccountGroupKey(clientAGK);
    },
  });

  const [productCategoriesQuery, productCategoriesQueryData] =
  useLazyQuery<getProductCategoriesForClient.ResultType>(getProductCategoriesForClient.query, {
    onCompleted: (data) => {
      const productCategoriesList = data!.productCategoriesForClient.map(({ id, name }) =>
      ({ id, name }));
      setProductCategories(productCategoriesList);
    },
  });

  const [articlesQuery, articlesQueryData] =
  useLazyQuery<getArticles.ResultType>(getArticles.query, { variables: { take: null, isHome: true } ,
    onCompleted: (data) => {
      const articlesList = data!.articles.items;
      setArticles(articlesList);
    },
  });

  const [allProductCategoriesQuery, allProductCategoriesQueryData] =
  useLazyQuery<getAllProductCategories.ResultType>(getAllProductCategories.query, {
    onCompleted: (data) => {
      const productCategoriesList = data!.allProductCategories
      .filter(category => category.active)
      .filter(category => category.accountGroupKey === clientAccountGroupKey)
      .map(({ id, name }) => ({ id, name }));
      setAllProductCategories(productCategoriesList);
    },
  });

  useEffect(() => {
    articlesQuery();
    if (!hasClientQueryCalled.current) {
      hasClientQueryCalled.current = true;
      clientQuery();
    } else {
      productCategoriesQuery();
      allProductCategoriesQuery();
    }
    // eslint-disable-next-line
  },        [clientAccountGroupKey]);

  const history = useHistory();
  const productCategoryRedirect = (id: number) => {
    const path = `/releases?${queryStringKeys.productCategoryKey}=${id}`;
    history.push(path);
  };

  const sectorRedirect = (id: number) => {
    const path = `/news/${id}`;
    history.push(path);
  };

  const newsRedirect = () => {
    const path = '/news';
    history.push(path);
  };

  const toggleAboutModal = () => {
    setIsAboutModalOpen(!isAboutModalOpen);
  };

  const toggleCategoryModal = () => {
    setIsCategoryModalOpen(!isCategoryModalOpen);
  };

  const categoryTileArray: {id: number, name: String, accessible: boolean}[] = [];
  allProductCategories.forEach((category) => {
    if (productCategories.some(pc => pc.id === category.id)) {
      categoryTileArray.push({ id: category.id, name: category.name, accessible: true });
    } else {
      categoryTileArray.push({ id: category.id, name: category.name, accessible: false });
    }
  });

  const sortedCategories = categoryTileArray.sort((a, b) => Number(b.accessible) - Number(a.accessible));

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: allProductCategories.length < 5 ? allProductCategories.length : 5,
    slidesToScroll: 4,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: allProductCategories.length < 4 ? allProductCategories.length : 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: allProductCategories.length < 3 ? allProductCategories.length : 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: allProductCategories.length < 2 ? allProductCategories.length : 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: allProductCategories.length < 1 ? allProductCategories.length : 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ovnSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    draggable: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {
      // tslint:disable-next-line:max-line-length
      (clientQueryData.loading || productCategoriesQueryData.loading || allProductCategoriesQueryData.loading || articlesQueryData.loading) ? (<Loading />) :
      (productCategoriesQueryData.error || allProductCategoriesQueryData.error) ? (
        <InlineAlert type={InlineAlertType.DataError}>
          <p>Unable to retrieve product categories from server</p>
        </InlineAlert>
      ) :
      (clientQueryData.error) ? (
        <InlineAlert type={InlineAlertType.DataError}>
          <p>Unable to retrieve client details from server</p>
        </InlineAlert>
      )
        : (
      <>
    <div className={styles.main_img}>
      <img
        alt="GfK Boutique"
        src={`https://${config.s3BucketName}/gfklanding.webp`}
      />
    </div>
    <Slider {...settings} className={styles.carousel}>
      {
        sortedCategories.filter(category => category.accessible).map((category, i) => (
            <div key={i} onClick={() => productCategoryRedirect(category.id)} className={styles.carousel_item}>
              <h3>{category.name}</h3>
            </div>
        ))
      }
      {
        sortedCategories.filter(category => !category.accessible).map((category, i) => (
            <div key={i} onClick={() => toggleCategoryModal()} className={styles.carousel_item_no_sub}>
              <h3>{category.name}</h3>
            </div>
        ))
      }
    </Slider>
    <div className={styles.ovnHeader}>
      <h1>{`OVN Digest | ${new Date(articlesQueryData.data?.articles.items[0].publishedAt).toLocaleString('en-US', { month: 'short', day: 'numeric' })}`}</h1>
      <h5 className={styles.moreNews} onClick={() => newsRedirect()}>More News<FaArrowRight className={styles.newsArrow}/></h5>
    </div>
    <Slider {...ovnSettings} className={styles.ovnCarousel}>
      {articles?.map(article => (
            <div key={article.id}>
                <Card className={`my-2 ${styles.fillGrid}`}>
                <CardBody style={{ padding: '1em' }}>
                  <CardTitle className={styles.title}>
                    {article.brands?.map((brand: { name: string; }) => brand.name).join(' | ')}</CardTitle>
                  {/*tslint:disable-next-line:max-line-length */}
                  <small className={`${styles.topright} text-muted`}>{new Date(article.publishedAt).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                  </small>
                  <CardText style={{ height: '155px', overflowY: 'auto', display: 'block' }}>
                    <small className="text-muted">
                      {article.body}
                    </small>
                  </CardText>
                    {/*tslint:disable-next-line:max-line-length */}
                    <div className={styles.pill}>
                      {(article.country && article.country.name !== 'UNCATEGORISED') &&
                        <Badge className={styles.badge} pill key={article.country.id}>{article.country.name}</Badge>}
                        <Badge className={styles.badge} pill key={article.sector.id}
                          onClick={() => sectorRedirect(article.sector.id)}>{article.sector.name}</Badge>
                        {
                          article.source &&
                            <CardLink href={article.source} target="_blank">
                              Source
                            </CardLink>
                        }
                        {
                          article.source2 &&
                            <CardLink href={article.source2} target="_blank">
                              Source 2
                            </CardLink>
                        }
                    </div>
                </CardBody>
              </Card>
            </div>
          ))}
    </Slider>
    <br />
    <div className={styles.card_container}>
      <Card className={styles.card_item}>
        <CardMedia
          className={styles.cardMedia}
          component="img"
          alt="About"
          image={`https://${config.s3BucketName}/about_team.webp`}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <CardContent className={styles.cardContent}>
          <Typography gutterBottom variant="h5" component="div">
            About GfK Boutique Research
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Find out more about the power of GfK data and how we support investment firms
          </Typography>
          <br />
          <Button onClick={() => toggleAboutModal()}>
            More
          </Button>
        </CardContent>
      </Card>

      <Card className={styles.card_item}>
        <CardMedia
          className={styles.cardMedia}
          component="img"
          alt="Coverage"
          image={`https://${config.s3BucketName}/coverage.webp`}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <CardContent className={styles.cardContent}>
          <Typography gutterBottom variant="h5" component="div">
            Our Coverage
          </Typography>
          <Typography variant="body2" color="textSecondary">
            GfK has an extensive coverage list - spanning from Consumer Technology and Home Appliances to smaller Consumer segments
          </Typography>
          <br />
          <Button onClick={() => window.open(`https://${config.s3BucketName}/coverageList.jpg`, '_blank')}>
            Take a look
          </Button>
        </CardContent>
      </Card>
    </div>
    <Modal isOpen={isAboutModalOpen} toggle={toggleAboutModal} className={styles.modal}>
      <ModalBody>
        <div className={styles.modal_item}>
          <h1>How GfK Boutique Research Supports Investment Firms</h1>
          <p>
            GfK Boutique leverages its proprietary point-of-sale (PoS) database,
            the largest in the world, to provide its clients with tangible data on consumer
            end-demand trends. Since 2002, Boutique has been the incumbent alternative-data provider
            for the buy-side, particularly in the Technology and Consumer Spaces, providing:
          </p>
          <ul>
            <li>
              À la carte data cuts and research reports from the world's largest PoS database, capturing
              over USD 1tr in annual consumer spending across 300 product categories
            </li>
            <li>
              Access to 30+ analysts who synthesize, analyze and forecast Boutique's PoS data in monthly
              reports and dashboards, illuminating consumer end-demand trends and adding their expert insight
              into product launches, product portfolio mix, ASP trends, inventory disconnects and other key
              performance indicators
            </li>
            <li>
              Weekly and monthly deliverables of model- and country-level data with access to raw data files
              for ingestion and systematic interpretation
            </li>
          </ul>
        </div>
        <hr className={styles.modalContentSeparator}></hr>
        <div className={styles.modal_container}>
          <div className={styles.modal_item}>
            <h1>About GfK Boutique Research</h1>
            <ul>
              <li>
                Exclusive to buy-side firms, servicing many of the largest funds in the Technology and Consumer spaces
              </li>
              <li>
                A global subsidiary of GfK, the world's fourth-largest market research organization
              </li>
              <li>
                Main offices in Boston, New York City, London and Hong Kong with local data-collection teams in countries
                represented within our panels
              </li>
            </ul>
          </div>
          <div className={styles.modal_item}>
            <h1>The Power of GfK Data</h1>
            <ul>
              <li>
                Panels were formed for manufacturers to better understand their end-consumer; in fact, over 2/3 of the
                companies in the coverage list utilize GfK data to inform their business decisions
              </li>
              <li>
                Proprietary PoS data is sourced directly through exclusive data-sharing agreements with 100,000+ unique retailers,
                resellers, distributors, VARs, mobile carriers and online channels in 75+ countries
              </li>
              <li>
                Correlations between GfK captured sell-out revenue and company reported revenue are provided for all forecasted brands,
                demonstrating a high level of historical accuracy
              </li>
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => toggleAboutModal()}>Close</Button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={isCategoryModalOpen} toggle={toggleCategoryModal} className={styles.modal}>
      <ModalBody>
        <div className={styles.modal_item}>
          <h1>Product Categories</h1>
          <p>
            You are not currently subscribed to this product category. To enquire about subscriptions please contact <a href = "mailto: Boutique-ClientSuccessTeam@gfk.com">Boutique-ClientSuccessTeam@gfk.com</a>
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => toggleCategoryModal()}>Close</Button>
      </ModalFooter>
    </Modal>
    </>
  )
}
</>
  );
};

export default Home;
