export interface AppConfig {
  region: string;
  userPoolId: string;
  userPoolClientId: string;
  s3BucketName: string;
  adminAppUrl: string;
  cookieExpiryDays: number;
}

export const getConfig = () => ({
  ...(window as any).appConfig,
  cookieExpiryDays: 90,
} as AppConfig);

export const isProduction = () => !(window as any).devMode;
