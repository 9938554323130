import Select from '../system/Select/Select';
import React, { CSSProperties, useEffect, useState } from 'react';
import { ValueType } from 'react-select/src/types';
import { ReleaseType } from 'src/enums';
import { CalendarView, DateRangePicker } from '../DateRangePicker';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { FlexDirectionProperty } from 'csstype';
interface FiltersProps {
  productCategoryValue?: SelectOption | null;
  startDateValue: Date | undefined;
  endDateValue: Date | undefined;
  productTypeValue?: SelectOption | null;
  releaseTypeValue?: SelectOption | null;
  onProductCategoryChange: (selectedOption: ValueType<SelectOption, false>) => void;
  onReleaseTypeChange: (selectedOption: ValueType<SelectOption, false>) => void;
  onProductTypeChange: (selectedOption: ValueType<SelectOption, false>) => void;
  productCategoryFilterOptions: SelectOption[];
  onStartDateChange: (startDate: Date | undefined) => void;
  onEndDateChange: (startDate: Date | undefined) => void;
}
export type SelectOption = { value: string; label: string };

const selectStyles = {
  container: (providedStyles: CSSProperties) => ({
    ...providedStyles,
    maxWidth: 230,
    width: '100%',
    marginRight: 24,
  }),
};

const Filters: React.FC<FiltersProps> = ({
  productCategoryValue,
  startDateValue,
  endDateValue,
  productTypeValue,
  releaseTypeValue,
  onStartDateChange,
  onEndDateChange,
  onProductCategoryChange,
  onReleaseTypeChange,
  onProductTypeChange,
  productCategoryFilterOptions = [],
}) => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isSmallScreen = viewportWidth <= 839;
  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },        []);
    // tslint:disable-next-line:max-line-length
  const filtersStyle = isSmallScreen ? { display: 'flex', flexDirection: 'column'  as FlexDirectionProperty, maxHeight: '500px', gap: '20px', backgroundColor: '#f5f5f5', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '83px', paddingTop: '20px' } :
    { display: 'flex', flexDirection: 'row' as FlexDirectionProperty, gap: '20px', paddingBottom: '20px' };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };
  const filters = (

    <div style={filtersStyle}>
      <div style={{ width: '100%', maxWidth: '380px', marginRight: 20, flexWrap: 'wrap' }}>
        <DateRangePicker
          calendarView={CalendarView.Month}
          onStartRangeSelect={onStartDateChange}
          onEndRangeSelect={onEndDateChange}
          selectedDate={{ startDate: startDateValue, endDate: endDateValue }}
          isClearable
          direction={'row'}
        />
      </div>
      <Select
        value={productCategoryValue}
        placeholder="Filter By Category"
        styles={selectStyles}
        options={productCategoryFilterOptions}
        onChange={onProductCategoryChange}
        isClearable
        inputId="1"
      />
      <Select
        value = {productTypeValue}
        placeholder="Filter By Product Type"
        styles={selectStyles}
        options={[
          { value: 'false', label: 'Core' },
          { value: 'true', label: 'Supplemental' },
        ]}
        onChange={onProductTypeChange}
        isClearable
        inputId="2"
      />
      <Select
        value = {releaseTypeValue}
        placeholder="Filter By Frequency"
        styles={selectStyles}
        options={[
          { value: ReleaseType.Weekly, label: 'Weekly' },
          { value: ReleaseType.Monthly, label: 'Monthly' },
        ]}
        onChange={onReleaseTypeChange}
        isClearable
        inputId="3"
      />
    </div>
  );
  return isSmallScreen ? (
    <>
      {/* tslint:disable-next-line:max-line-length */}
      <Button variant="outlined" color="primary" onClick={handleOpen} style={{ color: 'black', borderColor: 'black' }} >
        Filters
        {isPopoverOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
      </Button>
      <br />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isPopoverOpen ? 'top' : 'bottom',
          horizontal: 'left',
        }}
      >
        {filters}
      </Popover>
    </>
  ) : (
    filters
  );
};

export default Filters;
