import React, { ReactElement } from 'react';

interface TableCellProps {
  content: ReactElement;
}

const TableCell: React.FC<TableCellProps> = (props) => {
  const { content } = props;

  return (
    <td>
      {content}
    </td>
  );
};

export default TableCell;
