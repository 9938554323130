// tslint:disable:max-line-length
import React from 'react';

interface EcosystemLogoProps {
  width: number;
  height: number;
  title: string;
}

const EcosystemLogo: React.FC<EcosystemLogoProps> = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 281 281" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <g id={props.title}>
        <g id="Group">
          <polygon id="Path" fill="#E55A00" points="281 0.1 200.6 80.5 200.6 80.5 253.1 0.1 0.3 0.1 0.3 280.9 281 280.9"></polygon>
          <g transform="translate(21.000000, 82.000000)" fill="#FFFFFF">
            <path d="M195.4,54.1 L235.4,10 C236.8,8.3 237.9,7.1 237.9,5.9 C237.9,3.4 232.5,0.9 229,0.9 C226.5,
            0.9 224.4,2.8 222.6,4.9 L178.9,53.3 C176.8,55.6 176.4,56.8 176.4,58.1 C176.4,59.3 176.8,60.6 178.9,
            62.9 L222.6,111.3 C224.5,113.4 226.5,115.3 229,115.3 C232.5,115.3 237.9,112.8 237.9,110.3 C237.9,
            109.1 236.9,107.8 235.4,106.2 L195.4,62.1 C193.5,60 192.5,59 192.5,58.2 C192.5,57.2 193.5,56.1 195.4,
            54.1 L195.4,54.1 Z M155.8,110.6 C155.8,113.1 157.9,114.3 162.8,114.3 C167.8,114.3 169.8,113.1 169.8,110.6 L169.8,5.4 C169.8,2.9 167.7,
            1.7 162.8,1.7 C157.8,1.7 155.8,2.9 155.8,5.4 L155.8,110.6 Z M92.7,110.6 C92.7,113.1 94.8,114.3 99.7,114.3 C104.7,114.3 106.7,113.1 106.7,110.6 L106.7,40 C106.7,
            21.6 113.9,13.9 134,13.9 L137.7,13.9 C140.6,13.9 141.6,11.4 141.6,7.5 C141.6,2.7 138.9,1.7 131,1.7 C108.6,1.7 92.5,11.6 92.5,38.8 L92.5,110.6 L92.7,110.6 Z" id="Shape"></path>
            <path d="M77.2,103 L77.2,62.7 C77.2,60.2 75.1,58.9 70.2,58.9 C65.2,58.9 63.2,60.2 63.2,62.7 L63.2,
            96.4 C63.2,101.6 60.9,103.7 50.4,103.7 C29.1,103.7 15.8,87.4 15.8,59.6 C15.8,30.5 32.2,14 60.1,14 L68.2,
            14 C71.1,14 72.1,11.5 72.1,7.5 C72.1,2.7 69.4,1.6 61.5,1.6 L58.2,1.6 C25.7,1.6 0.6,19.6 0.6,59.7 C0.6,98.2 22.5,
            115.3 50.1,115.3 C72,115.4 77.2,109.3 77.2,103" id="Path"></path>
            <path d="M119.6,50.8 C123.9,50.8 127.3,54.2 127.3,58.5 C127.3,62.8 123.8,66.2 119.6,66.2 C115.3,66.2 111.9,
            62.7 111.9,58.5 C111.9,54.2 115.4,50.8 119.6,50.8" id="Path"></path>
            <path d="M195.4,54.1 L235.4,10 C236.8,8.3 237.9,7.1 237.9,5.9 C237.9,3.4 232.5,0.9 229,0.9 C226.5,0.9 224.4,
            2.8 222.6,4.9 L178.9,53.3 C176.8,55.6 176.4,56.8 176.4,58.1 C176.4,59.3 176.8,60.6 178.9,62.9 L222.6,111.3 C224.5,
            113.4 226.5,115.3 229,115.3 C232.5,115.3 237.9,112.8 237.9,110.3 C237.9,109.1 236.9,107.8 235.4,106.2 L195.4,
            62.1 C193.5,60 192.5,59 192.5,58.2 C192.5,57.2 193.5,56.1 195.4,54.1 L195.4,54.1 Z M155.8,110.6 C155.8,113.1 157.9,114.3 162.8,
            114.3 C167.8,114.3 169.8,113.1 169.8,110.6 L169.8,5.4 C169.8,2.9 167.7,1.7 162.8,1.7 C157.8,1.7 155.8,2.9 155.8,5.4 L155.8,
            110.6 Z M92.7,110.6 C92.7,113.1 94.8,114.3 99.7,114.3 C104.7,114.3 106.7,113.1 106.7,110.6 L106.7,40 C106.7,21.6 113.9,13.9 134,
            13.9 L137.7,13.9 C140.6,13.9 141.6,11.4 141.6,7.5 C141.6,2.7 138.9,1.7 131,1.7 C108.6,1.7 92.5,11.6 92.5,38.8 L92.5,110.6 L92.7,
            110.6 Z" id="Shape"></path>
            <path d="M77.2,103 L77.2,62.7 C77.2,60.2 75.1,58.9 70.2,58.9 C65.2,58.9 63.2,60.2 63.2,62.7 L63.2,96.4 C63.2,101.6 60.9,
            103.7 50.4,103.7 C29.1,103.7 15.8,87.4 15.8,59.6 C15.8,30.5 32.2,14 60.1,14 L68.2,14 C71.1,14 72.1,11.5 72.1,7.5 C72.1,2.7 69.4,
            1.6 61.5,1.6 L58.2,1.6 C25.7,1.6 0.6,19.6 0.6,59.7 C0.6,98.2 22.5,115.3 50.1,115.3 C72,115.4 77.2,109.3 77.2,103" id="Path"></path>
            <path d="M119.6,50.8 C123.9,50.8 127.3,54.2 127.3,58.5 C127.3,62.8 123.8,66.2 119.6,66.2 C115.3,66.2 111.9,62.7 111.9,58.5 C111.9,
            54.2 115.4,50.8 119.6,50.8" id="Path"></path>
          </g>
        </g>
        <polyline id="Path" fill="#FFFFFF" points="200.6 80.5 200.6 80.5 281 0.1 253.1 0.1 200.6 80.5"></polyline>
      </g>
    </svg>
  );
};

export default EcosystemLogo;
