import * as yup from 'yup';
import 'yup-phone';

const clientDetailsValidationSchema = yup.object({
  firstName: yup.string()
    .label('First Name')
    .required()
    .min(2)
    .max(75),
  lastName: yup.string()
    .label('Last Name')
    .required()
    .min(2)
    .max(75),
  contactNumber: yup.string()
    .label('Contact Number')
    .notRequired()
    .nullable()
    .phone(),
});

const clientEmailValidationSchema = yup.object({
  emailAddress: yup.string()
    .label('Email Address')
    .required()
    .max(255)
    .email(),
});

const verificationCodeValidationSchema = yup.object({
  verificationCode: yup.string()
    .label('Code')
    .required(),
});

const passwordValidationSchema = yup.object({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup.string()
    .label('New Password')
    .required()
    .min(8)
    .max(99)
    .matches(/[a-z]+/, { message: 'Requires a lowercase character' })
    .matches(/[A-Z]+/, { message: 'Requires an uppercase character' })
    .matches(/[0-9]+/, { message: 'Requires at least one number' })
    .matches(/[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`]+/, { message: 'Requires at least one special character' }),
  confirmNewPassword: yup.string()
     .oneOf([yup.ref('newPassword')], 'New password & confirm new password must match')
     .required('Password confirmation required'),
});

// This provides a type-safe way to ensure the object being validated has the correct shape
export const getClientDetailsValidationSchema = <T extends yup.InferType<typeof clientDetailsValidationSchema>>(_object?: T) =>
  clientDetailsValidationSchema;

export const getClientEmailValidationSchema = <T extends yup.InferType<typeof clientEmailValidationSchema>>(_object?: T) =>
  clientEmailValidationSchema;

export const getVerificationCodeValidationSchema = <T extends yup.InferType<typeof verificationCodeValidationSchema>>(_object?: T) =>
  verificationCodeValidationSchema;

export const getPasswordValidationSchema = <T extends yup.InferType<typeof passwordValidationSchema>>(_object?: T) =>
  passwordValidationSchema;
