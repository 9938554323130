import React from 'react';

import LayoutWrapper from '../../system/LayoutWrapper/LayoutWrapper';
import styles from './NotFound.module.scss';

const NotFound: React.FC = () => {
  return (
    <LayoutWrapper>
      <div className={styles.error_template}>
          <h1>
              Oops!</h1>
          <h2>
              404 Not Found</h2>
          <div className={styles.error_details}>
              Sorry, an error has occured, requested page not found!
          </div>
          <div className={styles.error_actions}>
              <a href="/">Take Me Home </a>
          </div>
      </div>
    </LayoutWrapper>
  );
};

export default NotFound;
