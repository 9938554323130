import React, { ReactElement } from 'react';
import styles from './LayoutWrapper.module.scss';

type LayoutWrapperProps = {
  children: ReactElement | ReactElement[];
};

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }: any) => <div className={styles.Wrapper}>{children}</div>;

export default LayoutWrapper;
