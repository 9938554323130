import { useCallback, useEffect, useMemo, useState } from 'react';

import { CalendarView } from '../DateRangePicker';

interface UseColorKeyboardSelectedType {
  colorKeyboardSelected: boolean;
  onYearChangeMonthView: (date: Date) => void;
  onCalendarOpenMonthView: () => void;
}
// React DatepPicker has a bug that does not allow us to style a single selected month properly,
// below there is a fix that works around that.
export const useColorKeyboardSelected = (
  calendarView: CalendarView,
  startDate: Date | undefined,
  endDate: Date | undefined,
): UseColorKeyboardSelectedType => {
  const [colorKeyboardSelected, setColorKeyboardSelected] = useState(false);

  const isOnlyStartOrEndDateSet = (!!startDate || !!endDate) && !(startDate && endDate);

  useEffect(() => {
    if (calendarView === CalendarView.Week) {
      return;
    }

    setColorKeyboardSelected(isOnlyStartOrEndDateSet);
  },        [isOnlyStartOrEndDateSet, calendarView]);

  const onYearChangeMonthView = useCallback(
    (date: Date) => {
      if (calendarView === CalendarView.Week || !isOnlyStartOrEndDateSet) {
        return;
      }

      let open = false;
      if (!!startDate && date.getFullYear() !== startDate.getFullYear()) {
        open = true;
      }

      if (!!endDate && date.getFullYear() !== endDate?.getFullYear()) {
        open = true;
      }

      setColorKeyboardSelected(!open);
    },
    [calendarView, endDate, isOnlyStartOrEndDateSet, startDate],
  );

  const onCalendarOpenMonthView = useCallback(
    () => calendarView === CalendarView.Month && isOnlyStartOrEndDateSet && setColorKeyboardSelected(true),
    [calendarView, isOnlyStartOrEndDateSet],
  );

  return useMemo(
    () => ({
      colorKeyboardSelected,
      onYearChangeMonthView,
      onCalendarOpenMonthView,
    }),
    [colorKeyboardSelected, onCalendarOpenMonthView, onYearChangeMonthView],
  );
};
